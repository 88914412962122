export type Features = {
  ADS_DEAL_LEVEL_CPM?: boolean;
  ALLOCATION_REPORT?: boolean;
  CREATIVE_SETS?: boolean;
  CREATIVE_BID_STREAM?: boolean;
  DEAL_TYPE_FILTER?: boolean;
  DIRECT_SALES_PLANNER?: boolean;
  DSP_SYNC?: boolean;
  DV360?: boolean;
  GOOGLEMAPS?: boolean;
  LEGACY_AUTOMATION_PUBLISHER?: boolean;
  INSIGHTS_LABS?: boolean;
  MTB_EXPORT?: boolean;
  NET_GROSS_VALUE?: boolean;
  NON_GUARANTEED_EXTENDED_TARGET?: boolean;
  OUT_OF_CHARGE?: boolean;
  OVERRIDE_DEAL_ID?: boolean;
  PLANNER?: boolean;
  PLANNER_NATIVE?: boolean;
  PROXIMITY?: boolean;
  VENUE_TYPE?: boolean;
  VISUAL_UNITS?: boolean;
  PACING_DASHBOARD?: boolean;
  INSIGHTS_DEAL_HEALTH_LABS?: boolean;
  YIELD_INSIGHTS_LABS?: boolean;
  PROOF_OF_PLAY_LABS?: boolean;
  NEW_INSIGHTS_PAGE?: boolean;
  DEAL_LINE_STRIP?: boolean;
};

export enum FeatureFlags {
  ADS_DEAL_LEVEL_CPM = 'ADS_DEAL_LEVEL_CPM',
  ALLOCATION_REPORT = 'ALLOCATION_REPORT',
  CREATIVE_SETS = 'CREATIVE_SETS',
  CREATIVE_BID_STREAM = 'CREATIVE_BID_STREAM',
  DEAL_TYPE_FILTER = 'DEAL_TYPE_FILTER',
  DIRECT_SALES_PLANNER = 'DIRECT_SALES_PLANNER',
  DSP_SYNC = 'DSP_SYNC',
  DV360 = 'DV360',
  GOOGLEMAPS = 'GOOGLEMAPS',
  LEGACY_AUTOMATION_PUBLISHER = 'TEVHQUNZX0FVVE9NQVRJT05fUFVCTElTSEVS',
  INSIGHTS_LABS = 'INSIGHTS_LABS',
  MTB_EXPORT = 'MTB_EXPORT',
  NET_GROSS_VALUE = 'NET_GROSS_VALUE',
  NON_GUARANTEED_EXTENDED_TARGET = 'NON_GUARANTEED_EXTENDED_TARGET',
  OUT_OF_CHARGE = 'OUT_OF_CHARGE',
  OVERRIDE_DEAL_ID = 'OVERRIDE_DEAL_ID',
  PLANNER = 'PLANNER',
  PLANNER_NATIVE = 'PLANNER_NATIVE',
  PROXIMITY = 'PROXIMITY',
  VENUE_TYPE = 'VENUE_TYPE',
  VISUAL_UNITS = 'VISUAL_UNITS',
  VISUAL_UNITS_ORDERING = 'VISUAL_UNITS_ORDERING',
  PACING_DASHBOARD = 'PACING_DASHBOARD',
  INSIGHTS_DEAL_HEALTH_LABS = 'INSIGHTS_DEAL_HEALTH_LABS',
  YIELD_INSIGHTS_LABS = 'YIELD_INSIGHTS_LABS',
  PROOF_OF_PLAY_LABS = 'PROOF_OF_PLAY_LABS',
  OSI_DELIVERY_METRICS = 'OSI_DELIVERY_METRICS',
  NEW_INSIGHTS_PAGE = 'NEW_INSIGHTS_PAGE',
  DEAL_LINE_STRIP = 'DEAL_LINE_STRIP',
}
