import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_CRITERIA } from 'consts/targetCriteria';
import { DirectSalesLineObjective } from 'components/common/types/DirectSalesCampaign.types';
import { CodeNameModel } from 'components/common/types';

export const DEFAULT_OBJECTIVE_SETTINGS = {
  isChange: false,
  isSubsectionChange: false,
  isDiscardModalOpen: false,
  isNextObjectiveModalOpen: false,
  objectiveToRemove: null,
  objectiveToCancel: null,
  nextObjective: null,
};

export interface ObjectiveSettings {
  isChange: boolean;
  isSubsectionChange: boolean;
  isDiscardModalOpen: boolean;
  isNextObjectiveModalOpen: boolean;
  objectiveToRemove: CodeNameModel | null;
  objectiveToCancel: CodeNameModel | null;
  nextObjective: DirectSalesLineObjective | null;
}

export interface ObjectiveStore {
  data: DirectSalesLineObjective;
  settings: ObjectiveSettings;
}

const initialState: ObjectiveStore = {
  data: {
    objectiveGroupId: 0,
    name: '',
    formats: [],
    locations: [],
    poi: {},
    tags: [],
    frameList: { routeFrameCodes: [], listFiles: [] },
    budget: {
      ...DEFAULT_CRITERIA,
      value: 0,
    },
    frames: {
      ...DEFAULT_CRITERIA,
      value: 0,
    },
    impressions: {
      ...DEFAULT_CRITERIA,
      value: 0,
    },
  },
  settings: DEFAULT_OBJECTIVE_SETTINGS,
};

export const objectiveSlice = createSlice({
  name: 'objective',
  initialState,
  reducers: {
    changeActiveObjective: (state, action: PayloadAction<Partial<DirectSalesLineObjective>>) => {
      return {
        data: {
          ...state.data,
          ...action.payload,
        },
        settings: initialState.settings,
      };
    },
    changeActiveObjectiveName: (state, action: PayloadAction<string>) => {
      state.data.name = action.payload;
    },
    clearActiveObjective: (state) => {
      state.data = initialState.data;
    },
    updateIsObjectiveChange: (state, action: PayloadAction<boolean>) => {
      state.settings.isChange = action.payload;
    },
    updateIsObjectiveSubsectionChange: (state, action: PayloadAction<boolean>) => {
      state.settings.isSubsectionChange = action.payload;
    },
    updateIsDiscardModalOpen: (state, action: PayloadAction<boolean>) => {
      state.settings.isDiscardModalOpen = action.payload;
    },
    updateIsNextObjectiveModalOpen: (state, action: PayloadAction<boolean>) => {
      state.settings.isNextObjectiveModalOpen = action.payload;
    },
    updateObjectiveToRemove: (state, action: PayloadAction<CodeNameModel | null>) => {
      state.settings.objectiveToRemove = action.payload;
    },
    updateObjectiveToCancel: (state, action: PayloadAction<CodeNameModel | null>) => {
      state.settings.objectiveToCancel = action.payload;
    },
    updateNextObjective: (state, action: PayloadAction<DirectSalesLineObjective | null>) => {
      state.settings.nextObjective = action.payload;
    },
    clearModals: (state) => {
      state.settings = initialState.settings;
    },
  },
});

export const {
  changeActiveObjective,
  changeActiveObjectiveName,
  clearActiveObjective,
  updateIsObjectiveChange,
  updateIsObjectiveSubsectionChange,
  updateIsDiscardModalOpen,
  updateIsNextObjectiveModalOpen,
  updateNextObjective,
  updateObjectiveToCancel,
  updateObjectiveToRemove,
  clearModals,
} = objectiveSlice.actions;

export default objectiveSlice.reducer;
