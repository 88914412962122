import { CodeNameModel } from 'components/common/types';
import { Color, TextPriority } from 'lib/Chip/Chip.types';

export enum SectionButtonTheme {
  SOLID,
  DASHED,
}

export enum SectionButtonRounded {
  NONE = 'rounded-none',
  DEFAULT = 'rounded-md',
  ROUNDED_TOP = 'rounded-t-md',
  ROUNDED_BOTTOM = 'rounded-b-md',
}

export enum SectionButtonShadow {
  DEFAULT = 'shadow-inner-sm',
  NONE = 'shadow-none',
}

export enum SectionButtonStatus {
  GREEN = 'bg-green-700',
  ORANGE = 'bg-orange-600',
  RED = 'bg-pinkRed-700',
}

export const ButtonThemes = {
  [SectionButtonTheme.SOLID]: {
    textColor: 'text-neutral-950',
    iconColor: 'text-neutral-600',
    buttonContainer: 'border-neutral-950-opacity-10',
    inactive: 'bg-white',
    disabled: 'bg-neutral-200 text-neutral-500',
  },
  [SectionButtonTheme.DASHED]: {
    textColor: 'text-primary-600',
    iconColor: 'text-primary-600',
    buttonContainer: 'border-primary-600',
    inactive: 'border-dashed',
    disabled: 'bg-neutral-200 border-neutral-500 text-neutral-500',
  },
};

export type SectionButtonChipType = CodeNameModel & {
  color?: Color;
};

export type SectionSelectedType =
  | {
      [key: string]: { included?: CodeNameModel[]; excluded?: CodeNameModel[] };
    }
  | { [key: string]: SectionButtonChipType[] }
  | string[];

export interface SectionButtonProps {
  theme: SectionButtonTheme;
  dataTestId?: string;
  icon?: string;
  label: string;
  isActive?: boolean;
  isEllipsisDisabled?: boolean;
  isDisabled?: boolean;
  onClear?: VoidFunction;
  onDuplicate?: VoidFunction;
  selected?: SectionSelectedType;
  isRequired?: boolean;
  onClick?: () => void;
  rounded?: SectionButtonRounded;
  shadow?: SectionButtonShadow;
  areChipsAutoCollapsed?: boolean;
  dataPendoId?: string | null;
  hasIndicator?: boolean;
  chipColor?: Color;
  chipTextPriority?: TextPriority;
  clearButtonLabel?: string;
  sectionGroupStatus?: SectionButtonStatus;
  enableAccordionToggle?: boolean;
  extendedChildren?: JSX.Element;
}
