import cx from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Line } from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';
import { usePlannerHeaderLineContainer } from 'components/pages/Planner/hooks/usePlannerHeaderLineContainer';
import {
  changeActiveDirectSalesLine,
  changeCurrentLine,
  changeDealData,
  createNewDirectSalesLine,
  createNewLineWithUniqueName,
  hidePlannerSecondaryPanel,
  removeTemporaryDealId,
  updateDirectSalesBackupFormDataLine,
} from 'store/dealManagement/reducer';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import useIsReadOnly from 'components/pages/Planner/useIsReadOnly';
import { CampaignNameAndStatus } from './CampaignDrawer/CampaignNameAndStatus/CampaignNameAndStatus';
import { DealLines } from './DealLines';
import CampaignActionButtons from './CampaignActionButtons';

interface CampaignHeaderProps {
  isAddNewLineDisabled?: boolean;
  isPlannerHeader?: boolean;
}

const CampaignHeader: React.FC<CampaignHeaderProps> = ({ isAddNewLineDisabled = false, isPlannerHeader = false }) => {
  const dispatch = useDispatch();
  const currentDealSummary = useSelector((state: Store) => state.dealManagement.commonDeal.summary);
  const isCampaignDrawerOpen = useSelector((state: Store) => state.dealManagement.isCampaignDrawerOpen);

  const { headerContainerRef, displayDealLinesDropdown, displayedLines, allLinesSaved, hasReachedMaxLines } =
    usePlannerHeaderLineContainer();
  const { isCampaignReadOnly } = useIsReadOnly();

  const { isDirectSalesCampaignType } = useCampaignType();

  const isAddingNewLineDisabled = isDirectSalesCampaignType
    ? isAddNewLineDisabled || isCampaignReadOnly
    : !allLinesSaved || hasReachedMaxLines || isAddNewLineDisabled;

  const isSelectLineDisabled = isDirectSalesCampaignType ? false : !allLinesSaved;

  const onNewLineClick = (): void => {
    if (isDirectSalesCampaignType) {
      dispatch(hidePlannerSecondaryPanel());
      dispatch(updateDirectSalesBackupFormDataLine());
      dispatch(createNewDirectSalesLine());
      return;
    }
    dispatch(createNewLineWithUniqueName());
  };

  const onLineSelect = (line: Line): void => {
    if (isDirectSalesCampaignType) {
      dispatch(changeActiveDirectSalesLine(line.lineId));
      dispatch(updateDirectSalesBackupFormDataLine());
      return;
    }
    dispatch(changeCurrentLine(line.id));
    dispatch(removeTemporaryDealId());
    dispatch(changeDealData({ summary: currentDealSummary }));
  };

  return (
    <header
      className="bg-neutral-50 border-t border-neutral-950-opacity-10 flex items-center sticky top-0 bg-white z-10"
      data-test-id="unified-header"
    >
      <div
        className={cx('border-l flex items-center border-neutral-950-opacity-10 h-full', {
          'border-r': isCampaignDrawerOpen,
          'border-b pl-[1px]': !isCampaignDrawerOpen,
        })}
      >
        <CampaignNameAndStatus isPlannerHeader={isPlannerHeader} />
      </div>
      <div
        data-test-id="unified-header-lines-list"
        className="whitespace-nowrap flex flex-1 align-items-center space-x-2 py-2 px-[16px] border-b border-neutral-950-opacity-10"
        ref={headerContainerRef}
      >
        <DealLines
          displayedLines={displayedLines}
          displayDealLinesDropdown={displayDealLinesDropdown}
          isAddNewLineDisabled={isAddingNewLineDisabled}
          isSelectLineDisabled={isSelectLineDisabled}
          onLineSelect={onLineSelect}
          onNewLineClick={onNewLineClick}
          addNewLineLabel="New deal line"
        />
      </div>
      <div className="flex items-center h-full border-b border-neutral-950-opacity-10">
        <CampaignActionButtons />
      </div>
    </header>
  );
};

export default CampaignHeader;
