import { DealStatus, FrontEndType } from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';
import { getActionConditions } from 'components/pages/DealWithLines/common/getActionConditions';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import { useCheckProgrammaticDealLevelChange } from 'components/pages/Planner/hooks/useCheckProgrammaticDealLevelChange';
import { useCheckProgrammaticLineLevelChange } from 'components/pages/Planner/hooks/useCheckProgrammaticLineLevelChange';
import useDirectSalesPlannerActions from 'components/pages/Planner/hooks/useDirectSalesPlannerActions';
import useProgrammaticPlannerActions from 'components/pages/Planner/hooks/useProgrammaticPlannerActions';
import { PermissionsEnum } from 'consts/permissions';
import Auth from 'modules/Auth';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeFormParams, resetForm } from 'store/dealManagement/reducer';
import { getModalVisibilityConditions } from 'store/dealManagement/selectors';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';

type UseHandleSaveButtonResult = {
  showAdvertiserInfoConfirmationModal: boolean;
  showSaveButton: boolean;
  isSaveDisabled: boolean;
  handleSave: () => void;
  handleUndoChanges: () => void;
  handleConfirmChanges: () => void;
};

const useHandleSaveButton = (): UseHandleSaveButtonResult => {
  const dispatch = useDispatch();

  const isDealLevelChange = useCheckProgrammaticDealLevelChange();
  const isLineLevelChange = useCheckProgrammaticLineLevelChange();
  const { saveDeal: saveDirectSalesDeal } = useDirectSalesPlannerActions();
  const { saveDeal: saveProgrammaticDeal } = useProgrammaticPlannerActions();
  const { isProgrammaticCampaignType, isDirectSalesCampaignType } = useCampaignType();
  const { shouldShowAdvertiserInfoModal } = useSelector(getModalVisibilityConditions);

  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const advertiser = useSelector((state: Store) => state.dealManagement.commonDeal.advertiser);
  const brand = useSelector((state: Store) => state.dealManagement.commonDeal.brand);
  const productCategory = useSelector((state: Store) => state.dealManagement.commonDeal.productCategory);
  const frontEndType = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.frontEndType);
  const isCampaignDrawerOpen = useSelector((state: Store) => state.dealManagement.isCampaignDrawerOpen);
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const isLoading = useSelector((state: Store) => state.dealManagement.isLoading);
  const dealManagementState = useSelector((state: Store) => state.dealManagement);
  const dealConfig = useSelector((state: Store) => state.dealConfig);
  const isAdServerMarket = useSelector(getIsAdServer);
  const isReseller = useSelector(getIsReseller);

  const { saveDealInfo } = getActionConditions(dealManagementState, isAdServerMarket, dealConfig, isReseller);

  const [showAdvertiserInfoConfirmationModal, setShowAdvertiserInfoConfirmationModal] = useState(false);

  const showSaveButton =
    isProgrammaticCampaignType && !!bookingStatusCode && frontEndType !== FrontEndType.STANDARD && isCampaignDrawerOpen;

  const isProgrammaticSaveDisabled = !(
    Auth.hasPermission(PermissionsEnum.DEAL_CONFIRM) &&
    !isEditingDisabled &&
    !isLoading &&
    !saveDealInfo.disabled &&
    (isDealLevelChange || isLineLevelChange) &&
    (bookingStatusCode === DealStatus.PENDING_APPROVAL ||
      bookingStatusCode === DealStatus.APPROVED ||
      bookingStatusCode === DealStatus.REJECTED ||
      bookingStatusCode === DealStatus.LIVE)
  );

  const isDirectSalesSaveDisabled = useMemo(() => {
    return !Object.values({
      advertiser,
      brand,
      productCategory,
    }).every(Boolean);
  }, [advertiser, brand, productCategory]);

  const handleSave = async (): Promise<void> => {
    if (isDirectSalesCampaignType) {
      try {
        await saveDirectSalesDeal();
        dispatch(changeFormParams({ isCampaignDrawerOpen: false }));
        // eslint-disable-next-line no-empty
      } catch {}

      return;
    }

    if (shouldShowAdvertiserInfoModal) {
      setShowAdvertiserInfoConfirmationModal(true);
      return;
    }

    saveProgrammaticDeal(null);
  };

  const handleUndoChanges = (): void => {
    dispatch(resetForm());
    setShowAdvertiserInfoConfirmationModal(false);
  };

  const handleConfirmChanges = (): void => {
    setShowAdvertiserInfoConfirmationModal(false);
    saveProgrammaticDeal(null);
  };

  return {
    showAdvertiserInfoConfirmationModal,
    handleSave,
    handleConfirmChanges,
    handleUndoChanges,
    showSaveButton,
    isSaveDisabled: isProgrammaticCampaignType ? isProgrammaticSaveDisabled : isDirectSalesSaveDisabled,
  };
};

export default useHandleSaveButton;
