import { isEmpty } from 'lodash';
import {
  DirectSalesLine,
  DirectSalesStatusOption,
  DirectSalesStatusOptionEvent,
  DirectSalesIntendedDealLineState,
  DirectSalesLineStateEvent,
  VALID_CAMPAIGN_VERSIONING_CONFIRMATION_STATES,
} from 'components/common/types/DirectSalesCampaign.types';
import { CancelFunctions } from 'components/common/types';
import { fetchCampaignState } from 'modules/api/directSalesCampaign';
import { CampaignVersioningEditingStatus, CampaignVersioningErrors } from 'consts/directSalesCampaignVersioning';
import { DirectSalesCampaignDealLineStateResponse } from 'components/common/types/DirectSalesCampaignResponse.types';

export const isEveryDealLineIsValidStatus = (
  filteredTransientStateLines: DirectSalesIntendedDealLineState,
): boolean => {
  if (isEmpty(filteredTransientStateLines)) return false;
  return Object.values(filteredTransientStateLines).every(({ event }) =>
    VALID_CAMPAIGN_VERSIONING_CONFIRMATION_STATES.includes(event),
  );
};

export const isEveryDealLineIsDraftPriced = (lines: DirectSalesLine[]): boolean => {
  if (!lines.length) return false;

  return Object.values(lines).every(({ state }) => state.status === DirectSalesStatusOption.DRAFT_PRICED);
};

export const hasOptionStatusAndNoDate = (transientLineStates: DirectSalesIntendedDealLineState): boolean => {
  return Object.values(transientLineStates).some(
    ({ event, expires }) => event === DirectSalesStatusOption.OPTION && !expires,
  );
};

export const mapDealLineStatesToEventState = (
  intendedDealLineStates: DirectSalesIntendedDealLineState,
): DirectSalesIntendedDealLineState => {
  return Object.fromEntries(
    Object.entries(intendedDealLineStates).map(([key, value]) => {
      const updatedValue: DirectSalesLineStateEvent = { ...value };

      Object.keys(updatedValue).forEach((eventKey: 'event' | 'expires') => {
        const mappedValue = DirectSalesStatusOptionEvent[updatedValue[eventKey as 'event']];
        if (mappedValue !== undefined) {
          updatedValue[eventKey as 'expires'] = mappedValue;
        }
      });

      return [key, updatedValue];
    }),
  );
};

export const isTransientCommitDisabled = ({
  areAllMandatoryFieldsFilled,
  transientStateDealLines,
  lines,
}: {
  areAllMandatoryFieldsFilled: boolean;
  transientStateDealLines?: DirectSalesIntendedDealLineState;
  lines: DirectSalesLine[];
}): boolean => {
  if (!areAllMandatoryFieldsFilled) {
    return true;
  }

  if (!isEmpty(transientStateDealLines) || !!transientStateDealLines) {
    const filteredTransientStateLines = Object.fromEntries(
      Object.entries(transientStateDealLines).filter(
        ([, value]) =>
          value.event !== DirectSalesStatusOption.DELETED && value.event !== DirectSalesStatusOption.CANCELLED,
      ),
    );

    const everyDealLineIsValidStatus = isEveryDealLineIsValidStatus(filteredTransientStateLines);

    const everyDealLineIsDraftPriced = isEveryDealLineIsDraftPriced(lines);

    return !everyDealLineIsValidStatus || !everyDealLineIsDraftPriced;
  }

  return true;
};

export const isValidAttemptToConfirmChanges = ({
  transientStateDealLines,
  showBannerInformation,
  lines,
}: {
  transientStateDealLines: DirectSalesIntendedDealLineState;
  showBannerInformation: (
    bannerInformation: CampaignVersioningErrors | CampaignVersioningEditingStatus,
  ) => Promise<void>;
  lines: DirectSalesLine[];
}): boolean => {
  const everyDealLineValidState = isEveryDealLineIsValidStatus(transientStateDealLines);

  const hasNewDealLineSinceOriginal = lines.length > Object.keys(transientStateDealLines).length;

  const newDealLinesDoNotHaveValidStatuses = !everyDealLineValidState || hasNewDealLineSinceOriginal;

  if (newDealLinesDoNotHaveValidStatuses) {
    showBannerInformation(CampaignVersioningErrors.STATUS_REQUIRED);

    return false;
  }

  const hasInvalidOptionState = hasOptionStatusAndNoDate(transientStateDealLines);

  if (hasInvalidOptionState) {
    showBannerInformation(CampaignVersioningErrors.OPTION_DATE_REQUIRED);

    return false;
  }

  return true;
};

export const getOriginalCampaignStatuses = async (
  marketId: string,
  campaignId: string,
  cancelFunctions: CancelFunctions,
): Promise<{
  fetchedCampaignStatuses: DirectSalesCampaignDealLineStateResponse;
  originalCampaignStatusesToIntendedFormat: DirectSalesIntendedDealLineState;
}> => {
  const fetchedCampaignStatuses = await fetchCampaignState(marketId, campaignId, cancelFunctions);

  const originalCampaignStatusesToIntendedFormat = Object.fromEntries(
    Object.entries(fetchedCampaignStatuses.states).map(([key, value]) => [key, { event: value.state.status }]),
  );

  return {
    fetchedCampaignStatuses,
    originalCampaignStatusesToIntendedFormat,
  };
};
