import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';

type PendoDataType = (label: string) => string;
interface UsePendoTrackingType {
  getPendoDataId: PendoDataType;
}

const usePendoTracking = (): UsePendoTrackingType => {
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const { pathname } = window.location;
  const getCurrentPageUrl = isNewDeal ? pathname : `${pathname.slice(0, pathname.lastIndexOf('/'))}/*`;

  const getPendoDataId = (label: string): string => `${getCurrentPageUrl} | ${label}`;

  return {
    getPendoDataId,
  };
};

export default usePendoTracking;
