import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';
import * as config from 'config';
import { PermissionsEnum } from 'consts/permissions';
import { LocalStoredAuthData } from './Auth.types';

let keyCloakInstance: Keycloak;

const getRedirectUri = (): string => {
  const url = new URL(`${config.app.protocol}://${config.app.host}`);
  if (config.app.port) {
    url.port = config.app.port;
  }
  url.pathname = `auth-callback/`;
  return url.toString();
};

const init = async (): Promise<void> => {
  if (keyCloakInstance) {
    return;
  }

  keyCloakInstance = new Keycloak({
    url: config.keycloak.url,
    realm: config.keycloak.realm,
    clientId: config.keycloak.clientId,
  });
  // eslint-disable-next-line no-useless-catch
  try {
    await keyCloakInstance.init({
      redirectUri: getRedirectUri(),
    });
    // eslint-disable-next-line sonarjs/no-useless-catch
  } catch (err) {
    throw err;
  }
};

const getData = (): LocalStoredAuthData => {
  const emptyResult = {
    userName: undefined,
    email: undefined,
    token: undefined,
    markets: [],
    permissions: [
      PermissionsEnum.SETUP_PAGE_ACCESS,
      PermissionsEnum.SETUP_DOWNLOAD,
      PermissionsEnum.SETUP_UPLOAD,
      PermissionsEnum.ENVIRONMENT_SELECTION_PAGE_ACCESS,
    ],
  };
  const token = localStorage.getItem('token');

  if (!token) {
    return emptyResult;
  }

  const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');

  return {
    token,
    userName: localStorage.getItem('username') || '',
    email: localStorage.getItem('email') || '',
    markets: JSON.parse(localStorage.getItem('markets') || '[]'),
    permissions:
      permissions.length > 0
        ? permissions
        : [
            PermissionsEnum.SETUP_PAGE_ACCESS,
            PermissionsEnum.SETUP_DOWNLOAD,
            PermissionsEnum.SETUP_UPLOAD,
            PermissionsEnum.ENVIRONMENT_SELECTION_PAGE_ACCESS,
          ],
  };
};

const getValidToken = (): Promise<string | undefined> => {
  return Promise.resolve(getData().token);
};

const isAuthenticated = (): boolean => {
  try {
    return !!keyCloakInstance.authenticated;
  } catch (error) {
    return false;
  }
};

const hasPermission = (permission: string): boolean => {
  const { permissions } = getData();
  return permissions.includes(permission);
};

const redirectToUniversalLoginPage = async (): Promise<void> => {
  await keyCloakInstance.login({ redirectUri: getRedirectUri() });
};

const getLogoutReturnUri = (): string => {
  const url = new URL(`${config.app.protocol}://${config.app.host}`);
  if (config.app.port) {
    url.port = config.app.port;
  }
  return url.toString();
};

const authenticate = async (): Promise<void> => {
  if (!isAuthenticated()) {
    await keyCloakInstance.login({ redirectUri: getRedirectUri() });
  } else {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { groups, preferred_username, realm_access } = keyCloakInstance.tokenParsed as KeycloakTokenParsed & {
      preferred_username: string;
      realm_access: { roles: string[] };
    };
    const token = keyCloakInstance.token as string;
    localStorage.setItem('token', token);
    localStorage.setItem('markets', JSON.stringify(groups || []));
    localStorage.setItem('username', `${preferred_username}` || '');
    localStorage.setItem('permissions', JSON.stringify(realm_access.roles || []));
  }
};

const logout = async (): Promise<void> => {
  await keyCloakInstance.logout({ redirectUri: getLogoutReturnUri() });
};

export default {
  init,
  redirectToUniversalLoginPage,
  isAuthenticated,
  hasPermission,
  authenticate,
  logout,
  getData,
  getValidToken,
};
