import { NetworkCriteriaType } from 'components/common/types/DirectSalesNetworks.types';
import { DirectSalesAllocationSolutionStatus } from 'components/common/types/DirectSalesAllocationSolution';
import { DirectSalesTargetTolerance } from 'components/common/types/DirectSalesTargetTolerance.types';
import { CampaignVersioningEditingStatus, CampaignVersioningErrors } from 'consts/directSalesCampaignVersioning';
import { PatternOption } from '../DaypartTargeting/DaypartTargeting.types';
import { CodeNameModel, FileList } from '.';
import { CommonDeal, Line, GroupOfSelectedDays, RouteFrameCode, TagInclude } from './Deal.types';

export enum DirectSalesSweep {
  NONE = 'NONE',
  SWEEP_TO_FILL = 'DIRTY',
  SWEEP_TO_HOUR = 'HOUR',
  SWEEP_TO_DAY = 'DAY',
  SWEEP_WEIGHTED = 'WEIGHTED',
}

export const DIRECT_SALES_SWEEP: Record<
  DirectSalesSweep,
  {
    label: string;
    description: string;
  }
> = {
  [DirectSalesSweep.NONE]: {
    label: '',
    description: '',
  },
  [DirectSalesSweep.SWEEP_TO_FILL]: {
    label: 'Sweep to fill',
    description: 'Sweep to fill any available SOT up to the exact Share of Time targeted in each hour',
  },
  [DirectSalesSweep.SWEEP_TO_HOUR]: {
    label: 'Hour sweep',
    description: 'Sweep to the exact Share of Time targeted in each hour',
  },
  [DirectSalesSweep.SWEEP_TO_DAY]: {
    label: 'Day sweep',
    description: 'Sweep to the exact Share of Time targeted in each day',
  },
  [DirectSalesSweep.SWEEP_WEIGHTED]: {
    label: 'Weight sweep',
    description: 'Allow the allocation engine to select any frame/hour combinations to optimise deal line allocation',
  },
};

export enum DirectSalesCampaignType {
  STANDARD = 'STANDARD',
}

export enum DirectSalesFilterRelationship {
  AND = 'AND',
  OR = 'OR',
}

export enum DirectSalesFilterGroup {
  LOCATION = 'LOCATION',
  TAG = 'TAG',
  LIST_TARGET = 'LIST_TARGET',
}

export enum DirectSalesSubOptionTypes {
  LOCATION = 'location',
  FORMAT = 'format',
}

export enum DirectSalesFilterGroupCriteria {
  TAG = 'TAG',
  VOLUME_TARGET = 'VOLUME_TARGET',
  BUDGET_TARGET = 'BUDGET_TARGET',
  FRAME_TARGET = 'FRAME_TARGET',
  SOT_TARGET = 'SOT_TARGET',
  FRAME_LIST = 'FRAME_LIST',
  NETWORK_TARGET = 'NETWORK_TARGET',
}

export enum DirectSalesFilterScope {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
}

enum DirectSalesDuplicationExclusion {
  ON = 'ON',
  OFF = 'OFF',
}

export enum DirectSalesMediaTypeLabel {
  DIGITAL = 'Digital',
  PAPER = 'Paper',
}

export enum DirectSalesMediaType {
  DIGITAL = 'DIGITAL',
  PAPER = 'PAPER',
}

export type DirectSalesTargetTolerancePriority = DirectSalesTargetTolerance & {
  priority: CodeNameModel;
  objectiveId?: number;
};

export enum DirectSalesPricingMode {
  CPM = 'CPM',
  PANEL = 'PANEL',
  NETWORK = 'NETWORK',
}

export const DIRECT_SALES_PRICING_MODES: Record<DirectSalesPricingMode, string> = {
  [DirectSalesPricingMode.CPM]: 'CPM',
  [DirectSalesPricingMode.PANEL]: 'Panel',
  [DirectSalesPricingMode.NETWORK]: 'Network',
};

export type DirectSales = {
  dealLineFormats: DirectSalesCodeNameModel[];
  locations: DirectSalesCodeNameModel[];
  transientState?: CampaignVersioningEditingStatus | CampaignVersioningErrors;
  mediaType?: DirectSalesMediaType;
  budgetCriteria: DirectSalesTargetTolerancePriority;
  framesCriteria: DirectSalesTargetTolerancePriority;
  impressionsCriteria: DirectSalesTargetTolerancePriority;
  networkCriteria: NetworkCriteriaType[];
  state: DirectSalesState;
  lockState: DirectSalesLockState;
  pricingMode: DirectSalesPricingMode;
  pricingSolution: DirectSalesPricingSolution | null;
  allocation: DirectSalesLineAllocation;
  objectives: DirectSalesLineObjective[];
  sessionLockState?: DirectSalesLockState;
  transientLineStates?: DirectSalesIntendedDealLineState;
};

export type DirectSalesLine = Line & DirectSales;

export interface DirectSalesCampaign extends CommonDeal, DirectSales {
  sessionState: DirectSalesLockState;
}

export interface DirectSalesCodeNameModel extends Pick<CodeNameModel, 'code' | 'name' | 'include'> {
  status?: DirectSalesFilterScope;
  category?: string;
}
export interface TagCategory extends DirectSalesCodeNameModel {
  tags: TagInclude[];
}

export type DirectSalesSubOptions = {
  [k in DirectSalesSubOptionTypes]?: CodeNameModel[];
};

export interface DirectSalesOption extends DirectSalesSubOptions {
  code: string;
  name: string;
}

export type DirectSalesCampaignHeader = {
  id: string;
  publisher: string;
  version: string;
  salesPerson?: CodeNameModel;
  salesTeam?: CodeNameModel;
  adminPerson?: CodeNameModel;
  sessionLockStatus: DirectSalesLockState;
};

export type DirectSalesCampaignDealLevelDefinition = {
  campaignGroup: DirectSalesCampaignType;
  campaignType: DirectSalesCampaignType;
  advertiserId: string;
  brandId: string;
  productCategory: string;
  agencyId: string;
  specialistId: string;
  salesPerson?: CodeNameModel | null;
  salesTeam?: CodeNameModel | null;
  adminPerson?: CodeNameModel | null;
  name?: string;
};

type DirectSalesCampaignDefinitionEnrichmentResponse = {
  advertiserName: string;
  brandName: string;
  productCategoryName: string;
  agencyName?: string;
  specialistName?: string;
  name?: string;
};

export type DirectSalesCampaignDefinitionRequest = DirectSalesCampaignDealLevelDefinition &
  DirectSalesCampaignMultiDealLineRequest & { duplicationExclusion?: DirectSalesDuplicationExclusion };

export type DirectSalesCampaignDefinitionResponse = Omit<DirectSalesCampaignDefinitionRequest, 'dealLines'> &
  DirectSalesCampaignDefinitionEnrichmentResponse & {
    dealLines: DirectSalesCampaignDealLineResponse[];
  };

export type DirectSalesAllocationSolutionResponse = {
  allocationSolutionId: string;
  createdAt: number;
  measures: {
    campaignMeasures: DirectSalesCampaignMeasure[];
    nbGreenObjectives: number;
    nbOrangeObjectives: number;
    nbRedObjectives: number;
  };
  errors: string[] | null;
};

export type DirectSalesAllocationMeasure = { allocated: number; status: DirectSalesAllocationSolutionStatus };

export type DirectSalesLineAllocation = {
  measures: Record<number, DirectSalesAllocationMeasure>;
  networks: DirectSalesTargetAllocation[];
};

export type DirectSalesTargetAllocation = {
  objectiveId: number;
  status?: DirectSalesAllocationSolutionStatus;
  value?: number;
};

export type DirectSalesObjectiveTarget = DirectSalesTargetTolerancePriority & {
  value?: number;
  objectiveId?: number;
};

export type DirectSalesLineObjective = {
  objectiveGroupId: number;
  objectiveStatus?: DirectSalesAllocationSolutionStatus;
  name: string;
  formats: DirectSalesCodeNameModel[];
  locations: DirectSalesCodeNameModel[];
  poi?: {};
  tags: CodeNameModel[];
  frameList: { listFiles: FileList[]; routeFrameCodes: RouteFrameCode[] };
  budget: DirectSalesObjectiveTarget;
  frames: DirectSalesObjectiveTarget;
  impressions: DirectSalesObjectiveTarget;
};

type DirectSalesPricingSolutionTotals = {
  [key: string]: string;
};

export type DirectSalesPricingSolution = {
  allocationSolutionUUID: string;
  pricingSetVersion: string;
  totalCampaignPrice: string;
  dealLineTotals: DirectSalesPricingSolutionTotals;
  pricingTypeTotals: DirectSalesPricingSolutionTotals;
  networkTotals: number | null;
  panelPriceTotals: number | null;
};

type DirectSalesAudienceMeasure = {
  contacts: number;
  key: string;
};

type DirectSalesCampaignMeasure = {
  campaignId: string;
  bookingMeasures: DirectSalesBookingMeasure[];
};

type DirectSalesBookingMeasure = {
  bookingId: string;
  objectiveMeasures: DirectSalesBookingObjectiveMeasure[];
  audienceMeasures: DirectSalesAudienceMeasure[];
  nbUnits: number;
  status: DirectSalesAllocationSolutionStatus;
};

type DirectSalesBookingObjectiveMeasure = {
  objectiveId: string;
  type: string;
  value: number;
  status: DirectSalesAllocationSolutionStatus;
  gapToTarget: number;
};

type DirectSalesCampaignDealLineRange = {
  startDate: string;
  endDate: string;
};

type DirectSalesFilterCriteriaId = {
  uuid: string;
};

export type DirectSalesFilterCriteria = {
  group: DirectSalesFilterGroup;
  criteriaType: DirectSalesFilterGroupCriteria;
  scope: DirectSalesFilterScope;
  values: DirectSalesFilterCriteriaId[];
  type: string;
};

export interface DirectSalesRequestFormatCriteria extends DirectSalesTargetTolerance {
  target: number;
  priority?: number;
  boostedTarget?: boolean;
  weight?: number;
  networkId?: string;
}

export type DirectSalesTargetCriteria = {
  objectiveId: number;
  value: DirectSalesRequestFormatCriteria;
  filter?: DirectSalesFilter;
  criteriaType: DirectSalesFilterGroupCriteria;
  objectiveStatus?: DirectSalesAllocationSolutionStatus;
  objectiveGroupId?: number;
  name?: string;
  type?: string;
};

export enum DirectSalesDaypartDurationType {
  HOUR = 'HOUR',
  DAYPART = 'DAYPART',
  DAY = 'DAY',
}

export enum DirectSalesStatusOption {
  DRAFT_UPDATING = 'DRAFT_UPDATING',
  DRAFT_SOLVED = 'DRAFT_SOLVED',
  DRAFT_FAILED_SOLVE = 'DRAFT_FAILED_SOLVE',
  DRAFT_PRICED = 'DRAFT_PRICED',
  DRAFT_FAILED_PRICE = 'DRAFT_FAILED_PRICE',
  DRAFT_SOLVING = 'DRAFT_SOLVING',
  DRAFT_SOLVING_OPTION_RESERVATION = 'DRAFT_SOLVING_OPTION_RESERVATION',
  DRAFT_SOLVING_CONFIRMED_RESERVATION = 'DRAFT_SOLVING_CONFIRMED_RESERVATION',
  OPTION = 'OPTION',
  PROPOSAL = 'PROPOSAL',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

export const DirectSalesStatusOptionEvent: Record<string, string> = {
  [DirectSalesStatusOption.OPTION]: 'TO_OPTION',
  [DirectSalesStatusOption.PROPOSAL]: 'TO_PROPOSAL',
  [DirectSalesStatusOption.CONFIRMED]: 'CONFIRM',
  [DirectSalesStatusOption.CANCELLED]: 'CANCEL',
  [DirectSalesStatusOption.DELETED]: 'DELETE',
};

export const VALID_CAMPAIGN_VERSIONING_CONFIRMATION_STATES = [
  DirectSalesStatusOption.DRAFT_PRICED,
  DirectSalesStatusOption.OPTION,
  DirectSalesStatusOption.PROPOSAL,
  DirectSalesStatusOption.CONFIRMED,
];

export enum DirectSalesLockStatusOption {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
}

export const DirectSalesStatusLabels: Record<string, string> = {
  DRAFT_UPDATING: 'Update Draft',
  PROPOSAL: 'Save as Proposal',
  OPTION: 'Save as Option',
  CONFIRMED: 'Confirm',
  CANCELLED: 'Cancel',
  DELETED: 'Delete',
};

export interface DirectSalesState {
  status: DirectSalesStatusOption;
  expires?: Date | string;
}

export interface DirectSalesLockState {
  status?: DirectSalesLockStatusOption;
  expires?: Date | string;
  owner?: string;
}

type DirectSalesCampaignDealLineDaypartHourRange = { hours: number[] };

export type DirectSalesCampaignDealLineDaypartDuration = {
  type: string;
  range: DirectSalesCampaignDealLineDaypartHourRange[] | [];
};

export type DirectSalesCampaignDealLineDaypartPattern = {
  week: number;
  days: string[];
  durations: DirectSalesCampaignDealLineDaypartDuration[];
};

export type DirectSalesCampaignDealLineRangePattern = {
  length: number;
  patterns: DirectSalesCampaignDealLineDaypartPattern[];
};

type CodeName = Pick<CodeNameModel, 'code' | 'name'>;

export type DirectSalesChannel = CodeName & {
  audienceCategoryGroupCode: string;
  dayPartGroupCode: string;
};

export type DirectSalesCampaignDealLineResponse = {
  id?: string;
  name: string;
  state: DirectSalesState;
  lockState: DirectSalesLockState;
  range: DirectSalesCampaignDealLineRange;
  pricingMode: DirectSalesPricingMode;
  rangePattern?: DirectSalesCampaignDealLineRangePattern;
  filterCriteria?: DirectSalesFilterCriteria[];
  targetCriteria: DirectSalesTargetCriteria[];
  creationTime?: string;
  sweep?: string;
  filter?: DirectSalesFilter;
};

export type DirectSalesTransformedDealLineRequest = Omit<
  DirectSalesCampaignDealLineResponse,
  'channelIds' | 'state' | 'lockState'
>;

export type DirectSalesUnitIncludedExcludedFilter = { code: string; filename: string | null };

export enum DirectSalesFilterTypes {
  AndFilter = 'AndFilter',
  OrFilter = 'OrFilter',
  NotFilter = 'NotFilter',
}

export type DirectSalesNestedFilter = {
  type: DirectSalesFilterTypes;
  filter: {
    type: DirectSalesFilterTypes;
    filters: (DirectSalesFilterItem | DirectSalesUnitListFilter)[];
  };
};

export type DirectSalesUnitListFilter = {
  type: string;
  filename?: string | null;
  unitsIds: string[];
};

export type DirectSalesMediaFilterItem = {
  type: string;
  mediaType: DirectSalesMediaType;
};

export type DirectSalesFilterItem = {
  type: string;
  id: string;
  name: string;
};

export type DirectSalesFilter = {
  type: DirectSalesFilterTypes;
  filters: (
    | DirectSalesFilterItem
    | DirectSalesNestedFilter
    | DirectSalesFilter
    | DirectSalesUnitListFilter
    | DirectSalesMediaFilterItem
  )[];
};

export type DirectSalesCampaignDealLineRequest = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  pricingMode: DirectSalesPricingMode;
  filter?: DirectSalesFilter;
  impressions?: number;
  budget?: number;
  frames?: number;
  budgetCriteria: DirectSalesTargetTolerance & {
    priority: CodeNameModel;
  };
  framesCriteria: DirectSalesTargetTolerance & {
    priority: CodeNameModel;
  };
  impressionsCriteria: DirectSalesTargetTolerance & {
    priority: CodeNameModel;
  };
  objectives?: DirectSalesLineObjective[];
  networkCriteria: NetworkCriteriaType[];
  sot?: number;
  sweep?: string;
  mediaType?: DirectSalesMediaType;
  selectedDays?: GroupOfSelectedDays;
  patternLength?: number;
  patternRepeatType?: PatternOption;
};

export type DirectSalesCampaignMultiDealLineRequest = {
  dealLines: DirectSalesTransformedDealLineRequest[];
};

export type DirectSalesLineStateEvent = {
  event: DirectSalesStatusOption;
  expires?: string | Date;
};

export type DirectSalesIntendedDealLineState = {
  [key: string]: DirectSalesLineStateEvent;
};

export enum DirectSalesTransientStates {
  ACCEPT = 'ACCEPT',
  CANCEL = 'CANCEL',
}

export type DirectSalesUpdateTransientDealStateRequest = {
  transientAction: string;
  intendedDealLineStates?: DirectSalesIntendedDealLineState;
};
