import { useLoadLookup } from 'components/common/FilterArea/Filters/useLoadLookup';
import { CodeNameModel } from 'components/common/types';
import { InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import { Store } from 'components/common/types/Store.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { ShadowStyle } from 'consts/shadow';
import { Padding } from 'lib/BaseSelect/BaseSelect.types';
import { Label } from 'lib/Label';
import { useDispatch, useSelector } from 'react-redux';
import { changeDealInfo } from 'store/dealManagement/actions';

const SelectAdminPerson: React.FC = () => {
  const dispatch = useDispatch();

  const adminPerson = useSelector((state: Store) => state.dealManagement.commonDeal.adminPerson);

  const { onLoadAdminPerson } = useLoadLookup();

  const handleSelectAdminPerson = (key: string, value: CodeNameModel): Promise<void> =>
    dispatch(changeDealInfo({ [key]: value }));

  return (
    <>
      <Label id="adminPerson" theme={InputTheme.FLAT_GRAY} label="Admin person" />
      <AsyncSelect
        name="adminPerson"
        dataTestId="async-select-adminPerson"
        placeholder=""
        theme={InputTheme.FLAT_GRAY}
        size={InputSize.EXTRA_SMALL}
        padding={Padding.NONE}
        shadow={ShadowStyle.NONE}
        shape={InputShape.ROUNDED}
        selectedValue={adminPerson}
        loadData={onLoadAdminPerson}
        onSelect={handleSelectAdminPerson}
        defaultOptions={adminPerson}
      />
    </>
  );
};

export default SelectAdminPerson;
