import { isEmpty } from 'lodash';
import { GroupOfSelectedDays } from '../types/Deal.types';
import { DAYS_IN_WEEK, LAST_DAY_INDEX } from './constants';
import { PatternOption } from './DaypartTargeting.types';

export const hasDaypartTargeting = (
  selectedDays: GroupOfSelectedDays | undefined = {},
  patternRepeatType: PatternOption,
  dateRangeLength: number,
): boolean => {
  if (isEmpty(selectedDays)) return false;

  const savedLength = Object.keys(selectedDays).length;

  const repeatPattern = {
    [PatternOption.NO_REPEAT]: 0,
    [PatternOption.REPEAT_ONE_WEEK]: DAYS_IN_WEEK,
    [PatternOption.REPEAT_TWO_WEEKS]: DAYS_IN_WEEK * 2,
  };

  const isNoRepeatIncorrect = dateRangeLength !== savedLength && patternRepeatType === PatternOption.NO_REPEAT;

  const isLengthIncorrect = repeatPattern[patternRepeatType] % savedLength !== 0;

  const someDayIsNotFullDay = Object.values(selectedDays).some(({ isFullDay }) => !isFullDay);

  return isNoRepeatIncorrect || isLengthIncorrect || someDayIsNotFullDay;
};

export const getStartDayId = (startDate: Date | string): number => {
  return (new Date(startDate).getDay() + LAST_DAY_INDEX) % DAYS_IN_WEEK;
};

export const isAllSelectedFullDays = (selectedDays: GroupOfSelectedDays = {}): boolean =>
  selectedDays && Object.values(selectedDays).every(({ isFullDay }) => isFullDay);
