import {
  DaypartHour,
  DaysOfTheWeek,
  FullLengthDaysOfTheWeek,
  PatternOption,
  PatternDropdownOption,
  DaypartTargetingDay,
} from './DaypartTargeting.types';

export const WEEKS_DAYS_ID = {
  1: [0, 6],
  2: [7, 13],
};

export const SECONDS_IN_AN_HOUR = 3600;

export const END_OFFSET_ADJUSTMENT = SECONDS_IN_AN_HOUR - 1;

export const DAYPART = {
  fullDay: {
    id: -1,
    dayPartId: -1,
    startOffset: 0,
    endOffset: 86399,
    displayName: 'Full Day',
  },
  dayPart: {
    id: -2,
    dayPartId: 0,
    startOffset: 0,
    endOffset: 86399,
    displayName: 'Day Part',
  },
};

export const DAYPART_HOURS: DaypartHour[] = [
  {
    id: 0,
    startOffset: 0,
    endOffset: 3599,
    startLabel: '00:00',
    endLabel: '00:59',
    displayName: '00:00',
  },
  {
    id: 1,
    startOffset: 3600,
    endOffset: 7199,
    startLabel: '01:00',
    endLabel: '01:59',
    displayName: '1:00',
  },
  {
    id: 2,
    startOffset: 7200,
    endOffset: 10799,
    startLabel: '02:00',
    endLabel: '02:59',
    displayName: '2:00',
  },
  {
    id: 3,
    startOffset: 10800,
    endOffset: 14399,
    startLabel: '03:00',
    endLabel: '03:59',
    displayName: '3:00',
  },
  {
    id: 4,
    startOffset: 14400,
    endOffset: 17999,
    startLabel: '04:00',
    endLabel: '04:59',
    displayName: '4:00',
  },
  {
    id: 5,
    startOffset: 18000,
    endOffset: 21599,
    startLabel: '05:00',
    endLabel: '05:59',
    displayName: '5:00',
  },
  {
    id: 6,
    startOffset: 21600,
    endOffset: 25199,
    startLabel: '06:00',
    endLabel: '06:59',
    displayName: '6:00',
  },
  {
    id: 7,
    startOffset: 25200,
    endOffset: 28799,
    startLabel: '07:00',
    endLabel: '07:59',
    displayName: '7:00',
  },
  {
    id: 8,
    startOffset: 28800,
    endOffset: 32399,
    startLabel: '08:00',
    endLabel: '08:59',
    displayName: '8:00',
  },
  {
    id: 9,
    startOffset: 32400,
    endOffset: 35999,
    startLabel: '09:00',
    endLabel: '09:59',
    displayName: '9:00',
  },
  {
    id: 10,
    startOffset: 36000,
    endOffset: 39599,
    startLabel: '10:00',
    endLabel: '10:59',
    displayName: '10:00',
  },
  {
    id: 11,
    startOffset: 39600,
    endOffset: 43199,
    startLabel: '11:00',
    endLabel: '11:59',
    displayName: '11:00',
  },
  {
    id: 12,
    startOffset: 43200,
    endOffset: 46799,
    startLabel: '12:00',
    endLabel: '12:59',
    displayName: '12:00',
  },
  {
    id: 13,
    startOffset: 46800,
    endOffset: 50399,
    startLabel: '13:00',
    endLabel: '13:59',
    displayName: '13:00',
  },
  {
    id: 14,
    startOffset: 50400,
    endOffset: 53999,
    startLabel: '14:00',
    endLabel: '14:59',
    displayName: '14:00',
  },
  {
    id: 15,
    startOffset: 54000,
    endOffset: 57599,
    startLabel: '15:00',
    endLabel: '15:59',
    displayName: '15:00',
  },
  {
    id: 16,
    startOffset: 57600,
    endOffset: 61199,
    startLabel: '16:00',
    endLabel: '16:59',
    displayName: '16:00',
  },
  {
    id: 17,
    startOffset: 61200,
    endOffset: 64799,
    startLabel: '17:00',
    endLabel: '17:59',
    displayName: '17:00',
  },
  {
    id: 18,
    startOffset: 64800,
    endOffset: 68399,
    startLabel: '18:00',
    endLabel: '18:59',
    displayName: '18:00',
  },
  {
    id: 19,
    startOffset: 68400,
    endOffset: 71999,
    startLabel: '19:00',
    endLabel: '19:59',
    displayName: '19:00',
  },
  {
    id: 20,
    startOffset: 72000,
    endOffset: 75599,
    startLabel: '20:00',
    endLabel: '20:59',
    displayName: '20:00',
  },
  {
    id: 21,
    startOffset: 75600,
    endOffset: 79199,
    startLabel: '21:00',
    endLabel: '21:59',
    displayName: '21:00',
  },
  {
    id: 22,
    startOffset: 79200,
    endOffset: 82799,
    startLabel: '22:00',
    endLabel: '22:59',
    displayName: '22:00',
  },
  {
    id: 23,
    startOffset: 82800,
    endOffset: 86399,
    startLabel: '23:00',
    endLabel: '23:59',
    displayName: '23:00',
  },
];

export const DAYS_OF_THE_WEEK: Record<number, DaysOfTheWeek> = {
  0: DaysOfTheWeek.SUNDAY,
  1: DaysOfTheWeek.MONDAY,
  2: DaysOfTheWeek.TUESDAY,
  3: DaysOfTheWeek.WEDNESDAY,
  4: DaysOfTheWeek.THURSDAY,
  5: DaysOfTheWeek.FRIDAY,
  6: DaysOfTheWeek.SATURDAY,
};

export const FULL_LENGTH_DAYS_OF_THE_WEEK: Record<number, FullLengthDaysOfTheWeek> = {
  0: FullLengthDaysOfTheWeek.SUNDAY,
  1: FullLengthDaysOfTheWeek.MONDAY,
  2: FullLengthDaysOfTheWeek.TUESDAY,
  3: FullLengthDaysOfTheWeek.WEDNESDAY,
  4: FullLengthDaysOfTheWeek.THURSDAY,
  5: FullLengthDaysOfTheWeek.FRIDAY,
  6: FullLengthDaysOfTheWeek.SATURDAY,
};

const getUpperCaseDaysOfTheWeek = (): Record<number, string> => {
  const result: Record<number, string> = {};

  Object.entries(FULL_LENGTH_DAYS_OF_THE_WEEK).forEach(([key, value]) => {
    result[parseInt(key)] = value.toUpperCase();
  });

  return result;
};

export const UPPERCASE_DAYS_OF_THE_WEEK = getUpperCaseDaysOfTheWeek();

export const DAYS_IN_WEEK = 7;

export const FIRST_DAY_INDEX = 0;

export const LAST_DAY_INDEX = 6;

export const PATTERN_DROPDOWN_OPTIONS: PatternDropdownOption[] = [
  {
    value: PatternOption.REPEAT_ONE_WEEK,
    text: '1 Week',
    isDisabled: false,
  },
  {
    value: PatternOption.REPEAT_TWO_WEEKS,
    text: '2 Weeks',
    isDisabled: false,
  },
  {
    value: PatternOption.NO_REPEAT,
    text: 'No repeat',
    isDisabled: false,
  },
];

export const DEFAULT_PATTERN_DROPDOWN_OPTION = PATTERN_DROPDOWN_OPTIONS[2];

export const DEFAULT_WEEKLY_PATTERN: DaypartTargetingDay[] = Object.values(DaysOfTheWeek).map((day, index) => ({
  date: new Date(),
  dayOfTheWeek: day,
  dayparts: DAYPART_HOURS.map((daypartHour) => ({
    date: new Date(),
    dayPartId: DAYPART.fullDay.id,
    displayName: '',
    endOffset: daypartHour.endOffset,
    id: daypartHour.id,
    dayId: `day-${index}`,
    isDisabled: false,
    isSelected: true,
    startOffset: daypartHour.startOffset,
  })),
  dayId: index,
  id: `day-${index}`,
  isDisabled: false,
  isFullDay: true,
  isSelected: true,
}));
