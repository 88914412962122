import PropTypes from 'prop-types';

export const ContentWidth = {
  DEFAULT: 'px-14',
  COMPACT: 'px-6',
};

export const SIDEBAR_WIDTH = {
  DEFAULT: 'w-95',
  LARGE: 'w-120',
};

const ContentWithRightSidebar = ({ content, sidebar, contentWidth, sidebarWidth }) => {
  return (
    <div className="flex flex-row">
      <div className="flex-1 min-h-mainContent">
        <div className={`pt-4 ${contentWidth}`}>{content}</div>
      </div>
      <div className={`${sidebarWidth} bg-neutral-100 border-l border-neutral-300`}>
        <div className={`fixed h-mainContent overflow-hidden ${sidebarWidth}`}>{sidebar}</div>
      </div>
    </div>
  );
};

ContentWithRightSidebar.propTypes = {
  content: PropTypes.element.isRequired,
  sidebar: PropTypes.element.isRequired,
  contentWidth: PropTypes.string,
  sidebarWidth: PropTypes.string,
};

ContentWithRightSidebar.defaultProps = {
  contentWidth: ContentWidth.DEFAULT,
  sidebarWidth: SIDEBAR_WIDTH.DEFAULT,
};

export default ContentWithRightSidebar;
