import React from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import closeSvg from 'assets/icons/close.svg';
import Button from 'lib/Button';
import IconButton from 'lib/IconButton';
import { ButtonSize } from 'lib/IconButton/IconButton.types';
import { AlertProps, AlertTheme, AlertThemeOption } from './Alert.types';

const Alert: React.FC<AlertProps> = ({
  icon,
  header,
  description,
  onCancel,
  onSave,
  onClose,
  theme = AlertThemeOption.PRIMARY,
  dataTestId = 'alert',
  cancelButtonLabel = 'Discard',
  confirmButtonLabel = 'Save selection',
}) => {
  return (
    <div data-test-id={dataTestId} className={cx('flex p-2 gap-2 sub-header-base rounded-md', AlertTheme[theme].base)}>
      <SVG src={icon} className="mt-0.5" />
      <div className="flex-1">
        <p>{header}</p>
        <p className="body-base">{description}</p>
        <div className="flex gap-3 mt-2">
          <button type="button" onClick={onCancel}>
            {cancelButtonLabel}
          </button>
          <Button label={confirmButtonLabel} onClick={onSave} color={AlertTheme[theme].buttonColor} classNames="py-1" />
        </div>
      </div>
      {onClose ? (
        <div className="w-4 h-4">
          <IconButton
            icon={closeSvg}
            name="objective-notification-close"
            dataTestId={`${dataTestId}-close`}
            buttonSize={ButtonSize.EXTRA_SMALL}
            handleOnClick={onClose}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Alert;
