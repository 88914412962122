import { PropsWithChildren, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import { Store } from 'components/common/types/Store.types';
import Button, { Color, Size } from 'lib/Button';
import chevronLeft from 'assets/icons/chevron_left.svg';
import closeSvg from 'assets/icons/close.svg';
import warningSvg from 'assets/icons/warning.svg';

import Alert from 'lib/Alert';
import { AlertThemeOption } from 'lib/Alert/Alert.types';
import { hidePlannerSecondaryPanel } from 'store/dealManagement/reducer';
import { PlannerSecondaryPanelProps } from './PlannerSecondaryPanel.types';
import useNotification from '../PlannerSections/Objectives/useNotification';

const PlannerSecondaryPanel: React.FC<PropsWithChildren<PlannerSecondaryPanelProps>> = ({
  children,
  customPosition,
  isLineLevel = true,
  title = '',
  isDisabled = false,
  showName = true,
  cancelButtonLabel = 'Cancel',
  confirmButtonLabel = 'Confirm',
  onSubmit,
  onCancel,
  onClose,
  isSubsection = false,
  isChange = false,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const dispatch = useDispatch();
  const dealLineName = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.name);
  const dealName = useSelector((state: Store) => state.dealManagement.commonDeal.dealName);

  const {
    closeNotification,
    handleBackNavigation,
    handleDiscard,
    isOpen: isAlertOpen,
  } = useNotification(isSubsection && isChange);

  const handleClose = useCallback((): void => {
    if (onClose) onClose(isChange);
    else dispatch(hidePlannerSecondaryPanel());
  }, [onClose, isChange]);

  const handleCancel = useCallback((): void => {
    if (isSubsection) handleBackNavigation();
    else onCancel();
  }, [onCancel, handleBackNavigation, isSubsection]);

  return (
    <div
      data-test-id="planner-secondary-panel"
      className="fixed !my-0 h-2/3 max-h-[754px] flex flex-col justify-between items-center rounded-md border border-gray-300 bg-neutral-50 z-50"
      style={{
        top: customPosition?.top ? `${customPosition.top}px` : '60px',
        left: customPosition?.left ? `${customPosition.left}px` : '460px',
        maxHeight: customPosition?.maxHeight ? `${customPosition.maxHeight}px` : '754px',
        width: customPosition?.width ? `${customPosition.width}px` : 'var(--secondary-panel-width)',
      }}
    >
      <div
        data-test-id="planner-secondary-panel-header"
        className={cx('flex w-full justify-between items-center border-gray-200 px-3', {
          'border-b py-3': !!title,
        })}
      >
        {!!title && (
          <div
            data-test-id="planner-secondary-panel-title"
            className="flex items-center justify-between gap-x-1 body-base font-semibold"
          >
            <button type="button" data-test-id="planner-secondary-panel-back" onClick={handleCancel}>
              <SVG src={chevronLeft} />
            </button>
            {title}
          </div>
        )}
        <div className="flex items-center justify-between gap-x-2 text-essential-tertiary">
          {showName && (
            <p
              data-test-id="planner-secondary-panel-value"
              className="w-32 body-base whitespace-nowrap overflow-hidden text-ellipsis text-right"
            >
              {isLineLevel ? dealLineName : dealName}
            </p>
          )}
          {onClose ? (
            <button type="button" data-test-id="planner-secondary-panel-close" onClick={handleClose}>
              <SVG src={closeSvg} />
            </button>
          ) : null}
        </div>
      </div>
      <div data-test-id="planner-secondary-panel-content" className="flex-1 w-full py-3 px-3 overflow-y-auto">
        {isAlertOpen ? (
          <div className="my-2">
            <Alert
              onSave={onSubmit}
              onCancel={handleDiscard}
              onClose={closeNotification}
              icon={warningSvg}
              theme={AlertThemeOption.YELLOW}
              header="Save selection?"
              description="Going back without saving will clear your selection."
            />
          </div>
        ) : null}
        {children}
      </div>
      <div
        data-test-id="planner-secondary-panel-actions"
        className="flex justify-between w-full border-t border-gray-200 p-2"
      >
        <Button label={cancelButtonLabel} size={Size.MEDIUM} color={Color.TERTIARY} onClick={handleCancel} />
        <Button
          label={confirmButtonLabel}
          size={Size.MEDIUM}
          color={Color.PRIMARY}
          onClick={() => onSubmit()}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default PlannerSecondaryPanel;
