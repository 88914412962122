import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import labsSvg from 'assets/icons/labs.svg';
import { toggleNewInsightsPage } from 'store/header/reducer';
import { Store } from 'components/common/types/Store.types';
import ToggleSwitch, { ToggleTheme } from 'components/patterns/ToggleSwitch';

const LabsSwitch: React.FC = () => {
  const dispatch = useDispatch();
  const showNewInsightsPage = useSelector((state: Store) => state.header.showNewInsightsPage);

  return (
    <div className="flex justify-center items-center space-x-3">
      {showNewInsightsPage && (
        <div className="flex justify-center items-center text-pink-600 space-x-1">
          <SVG src={labsSvg} className="w-4 h-4" />
          <span className="body-sm">Viooh Labs:</span>
        </div>
      )}
      <ToggleSwitch
        isChecked={showNewInsightsPage}
        onChange={() => {
          dispatch(toggleNewInsightsPage(!showNewInsightsPage));
        }}
        theme={ToggleTheme.PINK}
        dataTestId="insights-page-toggle"
      />
      <span className="body-sm">Try our new reports</span>
    </div>
  );
};

export default LabsSwitch;
