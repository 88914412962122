export enum PersonType {
  ADMIN = 'ADMIN',
  SALES = 'SALES',
}
export interface SalesPerson {
  name: string;
  email: string;
  person_type: PersonType;
  person_uuid: string;
  update_stamp: number;
}
