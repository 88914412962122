import { Store } from 'components/common/types/Store.types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePlannerSecondaryPanelType, PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import useUnsavedChange from './useUnsavedChange';

type UseOnChangePanelVisibilityReturnType = (nextPanelType: PlannerSecondaryPanelType) => ReturnType<VoidFunction>;

export const useOnChangePanelVisibility = (): UseOnChangePanelVisibilityReturnType => {
  const dispatch = useDispatch();
  const currentPanelType = useSelector((state: Store) => state.dealManagement.plannerSecondaryPanelType);

  const handleUnsavedChange = useUnsavedChange();

  return useCallback(
    (nextPanelType: PlannerSecondaryPanelType) => {
      handleUnsavedChange(() => {
        if (!nextPanelType || currentPanelType === nextPanelType) {
          dispatch(changePlannerSecondaryPanelType(PlannerSecondaryPanelType.NONE));
          return;
        }

        dispatch(changePlannerSecondaryPanelType(nextPanelType));
      });
    },
    [currentPanelType, handleUnsavedChange],
  );
};
