import { DirectSalesLineObjective } from 'components/common/types/DirectSalesCampaign.types';
import { DEFAULT_CRITERIA } from './targetCriteria';

export const DEFAULT_OBJECTIVE: DirectSalesLineObjective = {
  objectiveGroupId: 0,
  name: '',
  formats: [],
  locations: [],
  poi: {},
  tags: [],
  frameList: { routeFrameCodes: [], listFiles: [] },
  budget: DEFAULT_CRITERIA,
  frames: DEFAULT_CRITERIA,
  impressions: DEFAULT_CRITERIA,
};
