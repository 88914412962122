import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { isEqual } from 'lodash';

export const useCheckDirectSalesCampaignLevelChange = (): boolean => {
  const backupCommonDeal = useSelector((state: Store) => state.dealManagement.backupFormData.commonDeal);
  const commonDeal = useSelector((state: Store) => state.dealManagement.commonDeal);
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);

  const {
    advertiser: backupAdvertiser,
    brand: backupBrand,
    productCategory: backupProductCategory,
    agency: backupAgency,
    specialist: backupSpecialist,
  } = backupCommonDeal;
  const { advertiser, brand, agency, specialist, productCategory } = commonDeal;

  return useMemo(
    () =>
      !isNewDeal &&
      !isEqual(
        {
          advertiser: backupAdvertiser,
          brand: backupBrand,
          agency: backupAgency,
          specialist: backupSpecialist,
          productCategory: backupProductCategory,
        },
        { advertiser, brand, agency, specialist, productCategory },
      ),
    [backupCommonDeal, commonDeal, isNewDeal],
  );
};
