import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import labsSvg from 'assets/icons/labs.svg';
import { Color } from 'lib/Button';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { initialiseThoughtspot } from 'utils/thoughtspotInit';
import DropdownMenu from 'lib/DropdownMenu';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { DropdownMenuOption } from 'lib/DropdownMenu/DropdownMenu.types';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { DealHealthLiveboard } from './DealHealthLiveboard/DealHealthLiveboard';
import { YieldInsightsLiveboard } from './YieldInsightsLiveboard/YieldInsightsLiveboard';
import { ProofOfPlayLiveboard } from './ProofOfPlayLiveboard/ProofOfPlayLiveboard';

export const LabsReportDropdown: React.FC = () => {
  const cancelFunctions = useCancelRequest();

  const { localeCode } = useSelector((state: Store) => state.publisher.configuration);

  const [showDealHealthLiveboard, setShowDealHealthLiveboard] = useState(false);
  const [showYieldInsightsLiveboard, setShowYieldInsightsLiveboard] = useState(false);
  const [showProofOfPlayLiveboard, setShowProofOfPlayLiveboard] = useState(false);

  initialiseThoughtspot(cancelFunctions, localeCode);

  const labsReportDropdownOptions = (): DropdownMenuOption[] => {
    const options = [];

    if (useHasFeatureAccess(FeatureFlags.INSIGHTS_DEAL_HEALTH_LABS)) {
      options.push({
        id: 'deal-health',
        onClick: () => {
          setShowDealHealthLiveboard(true);
        },
        label: 'Deal health',
      });
    }

    if (useHasFeatureAccess(FeatureFlags.PROOF_OF_PLAY_LABS)) {
      options.push({
        id: 'proof-of-play',
        onClick: () => {
          setShowProofOfPlayLiveboard(true);
        },
        label: 'Proof of Play',
      });
    }

    if (
      Auth.hasPermission(PermissionsEnum.INSIGHTS_YIELD_VIEW) &&
      useHasFeatureAccess(FeatureFlags.YIELD_INSIGHTS_LABS)
    ) {
      options.push({
        id: 'yield-insights',
        onClick: () => {
          setShowYieldInsightsLiveboard(true);
        },
        label: 'Yield insights',
      });
    }

    return options;
  };

  return (
    <>
      <DropdownMenu dropdownMenuOptions={labsReportDropdownOptions()} icon={labsSvg} label="LABS" color={Color.PINK} />
      <DealHealthLiveboard showLiveboard={showDealHealthLiveboard} setShowLiveboard={setShowDealHealthLiveboard} />
      <ProofOfPlayLiveboard showLiveboard={showProofOfPlayLiveboard} setShowLiveboard={setShowProofOfPlayLiveboard} />
      <YieldInsightsLiveboard
        showLiveboard={showYieldInsightsLiveboard}
        setShowLiveboard={setShowYieldInsightsLiveboard}
      />
    </>
  );
};
