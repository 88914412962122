/* eslint-disable sonarjs/cognitive-complexity */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { getLookupData } from 'modules/api/lookups';
import multiPublisherRequest from 'modules/api/multiPublisherRequest';
import withCancelRequest from 'components/hocs/withCancelRequest';
import { SOURCE_SYSTEM } from 'consts/sourceSystem';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { useDealFormReadOnly } from 'customHooks/useDealFormReadOnly';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import { RouteFrameCode } from 'components/common/types/Deal.types';
import Chips from 'components/patterns/Chips';
import { Label } from 'lib/Label';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { getIsReseller } from 'store/publisher/selectors';
import { getResellerDealMediaOwners } from 'store/reseller/selectors';
import { RootState } from 'store';
import { CodeNameModel, FileList } from 'components/common/types/index';
import { updateFrameSelection } from 'utils/frameList';
import ListUpload from './ListUpload';
import InfoMessage from '../InfoMessage';

type ListProps = {
  closeMenuOnSelect?: boolean;
};

const List: React.FC<ListProps> = ({ closeMenuOnSelect = true }) => {
  const cancelFunctions = useCancelRequest();
  const dispatch = useDispatch();
  const resellerMediaOwners = useSelector(getResellerDealMediaOwners);
  const isReseller = useSelector(getIsReseller);
  const { environmentId } = useSelector((state: RootState) => state.environment);
  const sourceSystem = useSelector((state: Store) => state.dealManagement.commonDeal.sourceSystem);
  const routeFrameCodes = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.routeFrameCodes);
  const listFiles = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.listFiles);

  const readOnly = useDealFormReadOnly();
  const isSourceSystemAutomation = sourceSystem === SOURCE_SYSTEM.AUTOMATION;
  const [routeFrameCodesWithLabels, setRouteFrameCodesWithLabels] = useState<
    (RouteFrameCode & { label: string })[] | null
  >([]);

  const fetchRouteFrameCodes = async (
    dropdownName: string,
    lookupCode: string,
  ): Promise<{ code: string; mediaOwner: string; label?: string }[]> => {
    const requestArguments = { cancelFunctions, lookupURLName: dropdownName, query: '', lookupCode };
    try {
      if (isReseller) {
        const response = (await multiPublisherRequest(resellerMediaOwners, getLookupData, {
          ...requestArguments,
        })) as { publisher: string; code: string }[];
        return response.map(({ publisher, code }) => ({
          code,
          mediaOwner: publisher,
          label: `${publisher} : ${code}`,
        }));
      }

      const response: { code: string }[] = await getLookupData({ ...requestArguments });
      return response.map(({ code }) => ({ code, mediaOwner: environmentId, label: code }));
    } catch {
      return [];
    }
  };

  const handleFrameListUpload = (newListFiles: FileList[]): void => {
    dispatch(changeDealCurrentLineData({ listFiles: newListFiles }));
  };

  useEffect(() => {
    setRouteFrameCodesWithLabels(
      routeFrameCodes?.map((routeFrame) => ({
        ...routeFrame,
        label: isReseller ? `${routeFrame.mediaOwner} : ${routeFrame.code}` : routeFrame.code,
      })) || [],
    );
  }, [routeFrameCodes]);

  const onIncludeExcludeChange = useCallback(
    (value: CodeNameModel): void => {
      if (!routeFrameCodes) return;

      const frameCodes = structuredClone(routeFrameCodes);
      const updatedFrameCodes = updateFrameSelection(frameCodes, value);

      dispatch(changeDealCurrentLineData({ routeFrameCodes: updatedFrameCodes }));
    },
    [routeFrameCodes],
  );

  const onRemoveItem = useCallback(
    (code: string) => {
      const newFrameSelection = routeFrameCodes?.filter((frameCode) => frameCode.code !== code);
      dispatch(changeDealCurrentLineData({ routeFrameCodes: newFrameSelection }));
    },
    [routeFrameCodes],
  );

  const onSelect = (value: RouteFrameCode[]): void => {
    const newFrameSelection = value.map((item: RouteFrameCode) => ({
      ...item,
      include: item.include !== false,
    }));

    dispatch(changeDealCurrentLineData({ routeFrameCodes: newFrameSelection }));
  };

  return (
    <div className="grid grid-cols-2 gap-x-7 xl:gap-x-24 px-12 py-6" data-test-id="list">
      <div>
        <Label label="Frames" />
        <div className="mb-1.5">
          <AsyncSelect
            dataTestId="routeframecode-input"
            name="routeframecode"
            loadData={fetchRouteFrameCodes}
            selectedValue={routeFrameCodesWithLabels}
            placeholder="Search route frame"
            onSelect={(_: string, value: RouteFrameCode[]) => onSelect(value)}
            isClearable={false}
            multiple
            isMultiValueVisible={false}
            closeMenuOnSelect={closeMenuOnSelect}
            valueKey="code"
            textKey="label"
            isDisabled={readOnly || isSourceSystemAutomation}
          />
          {isSourceSystemAutomation && (
            <div className="mt-4">
              <InfoMessage>
                To find the list of frames targeted, please refer to Frame list in the summary panel.
              </InfoMessage>
            </div>
          )}
        </div>
        {!isSourceSystemAutomation && routeFrameCodes?.length ? (
          <Chips
            dataTestId="list-chips"
            dataList={routeFrameCodesWithLabels}
            idKey="code"
            labelKey="label"
            onRemoveAll={() => dispatch(changeDealCurrentLineData({ routeFrameCodes: [] }))}
            onRemove={({ code }) => onRemoveItem(code)}
            excludeKey="include"
            onIncludeExcludeChange={(value, newState) => {
              onIncludeExcludeChange({ ...value, include: newState });
            }}
            isDisabled={readOnly}
          />
        ) : null}
      </div>
      <ListUpload readOnly={readOnly} listFiles={listFiles} onChangeListFiles={handleFrameListUpload} />
    </div>
  );
};

export default withCancelRequest(List);
/* eslint-enable sonarjs/cognitive-complexity */
