import { CampaignDrawer } from 'components/common/Deal/CampaignHeader/CampaignDrawer/CampaignDrawer';
import { FrontEndType } from 'components/common/types/Deal.types';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const DealWithRightSidebar = ({ sidebar, header, leftContent, rightContent }) => {
  const isCampaignDrawerOpen = useSelector((state) => state.dealManagement.isCampaignDrawerOpen);

  return (
    <div className="h-full flex flex-col">
      {header}
      <div className="flex overflow-hidden">
        {isCampaignDrawerOpen ? (
          <CampaignDrawer campaignView={FrontEndType.STANDARD} />
        ) : (
          <div className="sticky top-5 z-1 place-self-start" data-test-id="campaign-drawer-hidden">
            {leftContent}
          </div>
        )}
        <div className="flex-grow h-full overflow-auto">
          <div className="px-8 min-w-[848px] mt-5">{rightContent}</div>
        </div>
        <div className="bg-neutral-100 border-l border-neutral-300 shrink-0">{sidebar}</div>
      </div>
    </div>
  );
};

DealWithRightSidebar.propTypes = {
  sidebar: PropTypes.element.isRequired,
  header: PropTypes.element.isRequired,
  leftContent: PropTypes.element.isRequired,
  rightContent: PropTypes.element.isRequired,
};

export default DealWithRightSidebar;
