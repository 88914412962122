import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { first, xor } from 'lodash';
import {
  Allocation,
  Availability,
  DealProcessingStatuses,
  DealStatus,
  PreservedAvailability,
} from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';
import { BookingCode } from 'components/pages/Planner/hooks/usePlannerActions.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { getIsAdServer } from 'store/publisher/selectors';
import { clearNotification, notifyError, notifyInfo, notifySuccess } from 'store/notification/reducer';
import {
  changeCurrentLineToLastLine,
  changeDealCurrentLineAvailabilityAssetsSot,
  changeDealCurrentLineData,
  changeDealData,
  changeDealLineAllocatedImpressions,
  changeFormParams,
  removeTemporaryDealId,
} from 'store/dealManagement/reducer';
import { getDealDetails } from 'store/dealManagement/actions';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import {
  approveDeal,
  cancelDeal,
  checkAllocate,
  createDeal,
  createLine,
  deleteLine,
  editDeal,
  getAllocatedDeal,
  getDealProcessingStatus,
  rejectDeal,
  reserveDeal,
  stopLine,
  updateLine,
} from 'modules/api/adsDeal';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { useCheckProgrammaticDealLevelChange } from 'components/pages/Planner/hooks/useCheckProgrammaticDealLevelChange';
import { useCheckProgrammaticLineLevelChange } from 'components/pages/Planner/hooks/useCheckProgrammaticLineLevelChange';
import { transformBookingStatusCode } from '../../common/transformPostData';
import { getDealData, getDealWithLineFormData, getLineFormData } from '../transformPostData';
import { combineProposalAllocationAndDealSummary, transformAvailability } from '../transformDealDetails';

interface ErrorMessage {
  lineId: string;
  message: string;
}
interface DealRequestResult {
  campaignID: string;
}

interface ProcessingStatusRequestResult extends DealRequestResult {
  messages: ErrorMessage[];
  dealIds?: string;
  processingStatus: DealProcessingStatuses;
}
interface LineRequestResult extends DealRequestResult {
  dealIds: string;
  dealId: string;
}

type DealRequestHandler<TResult> = () => Promise<TResult>;
type ProcessingStatusPollHandler<TResult> = (result: TResult) => void;
type CatchHandler = () => void;

interface UseDealAndLineActions {
  createLineHandler: () => Promise<void>;
  deleteLineHandler: () => Promise<void>;
  stopLineHandler: () => Promise<void>;
  updateLineHandler: () => Promise<void>;
  checkAllocateHandler: () => Promise<void>;
  saveDeal: (bookingStatusCode: BookingCode, catchHandler?: CatchHandler) => void;
}
export const useDealAndLineActions = (): UseDealAndLineActions => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dealConfig = useSelector((state: Store) => state.dealConfig);
  const commonDeal = useSelector((state: Store) => state.dealManagement.commonDeal);
  const programmatic = useSelector((state: Store) => state.dealManagement.programmatic);
  const dealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const backupDealSummary = useSelector((state: Store) => state.dealManagement.backupFormData.commonDeal.summary);
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const cpm = useSelector((state: Store) => state.dealManagement.commonDeal.cpm);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const lines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);
  const currentLine = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine);
  const isCpmCampaignLevel = useSelector((state: Store) => state.dealManagement.isCpmCampaignLevel);

  const isAdServerMarket = useSelector(getIsAdServer);

  const isDealLevelChange = useCheckProgrammaticDealLevelChange();
  const isLineLevelChange = useCheckProgrammaticLineLevelChange();
  const hasAdsDealLevelCPMEnabled = useHasFeatureAccess(FeatureFlags.ADS_DEAL_LEVEL_CPM);
  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);
  const cancelFunctions = useCancelRequest();

  const updateDealDetails = async (campaignID: string): Promise<void> => {
    if (dealId) {
      await dispatch(
        getDealDetails(
          dealId,
          cancelFunctions,
          navigate,
          dealConfig,
          hasAdsDealLevelCPMEnabled,
          hasNonGuaranteedExtendedTargetEnabled,
          isAdServerMarket,
        ),
      );
    } else if (campaignID) {
      navigate(`/deal/${campaignID}`, { replace: true });
    } else {
      navigate('/deals', { replace: true });
    }
  };

  const handleMessages = (messages: ErrorMessage[]): void => {
    const errors = messages.map(({ lineId, message }) => {
      const lineName = lines.find(({ id }) => id === lineId)?.name || currentLine.name;

      return `${lineName}: ${message}`;
    });

    dispatch(clearNotification());
    dispatch(notifyError({ message: errors.join('\n'), timeout: NOTIFICATION_TIMEOUT.LONG }));
  };

  const dealStatusComplete = async (result: ProcessingStatusRequestResult): Promise<void> => {
    dispatch(notifySuccess({ message: `Deal saved Successfully - ${result.campaignID}` }));

    dispatch(changeFormParams({ isForecastedAllocation: bookingStatusCode === DealStatus.PENDING_APPROVAL }));
    updateDealDetails(result.campaignID);
  };

  const lineStatusComplete = async (result: LineRequestResult): Promise<void> => {
    dispatch(notifySuccess({ message: `Success! Line ${currentLine.name} was saved` }));

    dispatch(changeFormParams({ isForecastedAllocation: bookingStatusCode === DealStatus.PENDING_APPROVAL }));
    dispatch(changeDealCurrentLineData({ isCurrentLineWithProposalAllocation: false }));
    updateDealDetails(result.dealId);
  };

  const handleDealRequest = async <TResult extends DealRequestResult>(
    requestHandler: DealRequestHandler<TResult>,
    pollCompleteHandler: ProcessingStatusPollHandler<TResult>,
    catchHandler: CatchHandler = () => undefined,
  ): Promise<void> => {
    dispatch(changeFormParams({ isEditingDisabled: true }));
    dispatch(notifyInfo({ message: 'We are validating your solution', timeout: NOTIFICATION_TIMEOUT.NEVER }));

    try {
      const result = await requestHandler();
      const poll = getDealProcessingStatus(result.campaignID || dealId, cancelFunctions);

      await poll(
        ({
          data: { processingStatus, messages, campaignID },
        }: {
          data: { processingStatus: string; messages: ErrorMessage[]; campaignID: string };
        }) => {
          if (processingStatus === DealProcessingStatuses.ERROR) {
            if (messages) handleMessages(messages);
            updateDealDetails(campaignID);
            dispatch(changeFormParams({ isEditingDisabled: false }));
            return false;
          }

          if (processingStatus === DealProcessingStatuses.STILL_PROCESSING) {
            return true;
          }

          pollCompleteHandler(result);
          if (messages.length) handleMessages(messages);
          dispatch(changeFormParams({ isEditingDisabled: false }));
          return false;
        },
        1000,
      );
    } catch {
      catchHandler();
      dispatch(changeFormParams({ isEditingDisabled: false }));
    }

    dispatch(removeTemporaryDealId());
  };

  const approveDealHandlerRequest = async (): Promise<void> => {
    await handleDealRequest(() => approveDeal(cancelFunctions, { dealId }), dealStatusComplete);
  };

  const updateDealAndApprove = async (catchHandler: CatchHandler): Promise<void> => {
    const data = {
      ...getDealData(
        {
          ...commonDeal,
          ...programmatic,
          cpm: isCpmCampaignLevel ? cpm : null,
          hasAdsDealLevelCPMEnabled,
        },
        isAdServerMarket,
      ),
      bookingStatusCode: undefined,
    };

    await handleDealRequest(() => editDeal(cancelFunctions, dealId, data), approveDealHandlerRequest, catchHandler);
  };

  const updateLineAndApprove = async (): Promise<void> => {
    const formData = getLineFormData(commonDeal, { isAdServerMarket }, isCpmCampaignLevel);
    const lineId =
      currentLine.id ??
      (await new Promise<string>((resolve) => {
        handleDealRequest<LineRequestResult>(
          () => createLine(cancelFunctions, formData, { dealId }),
          async (result) => {
            await lineStatusComplete(result);

            const lineIds = lines.map((line) => line.lineId);

            resolve(first(xor(result.dealIds, lineIds)) || '');
          },
        );
      }));

    await handleDealRequest<DealRequestResult>(
      () =>
        updateLine(cancelFunctions, formData, {
          dealId,
          lineId,
        }),
      approveDealHandlerRequest,
    );
  };

  const submitNewDeal = async (bookingCode: BookingCode): Promise<void> => {
    const formData = getDealWithLineFormData(
      {
        ...commonDeal,
        ...programmatic,
        cpm: isCpmCampaignLevel ? cpm : null,
        bookingStatusCode: transformBookingStatusCode(bookingCode),
        hasAdsDealLevelCPMEnabled,
      },
      { isAdServerMarket },
    );
    await handleDealRequest(() => createDeal(cancelFunctions, formData), dealStatusComplete);
  };

  const approveDealHandler = async (catchHandler: CatchHandler): Promise<void> => {
    if (!isDealLevelChange && !isLineLevelChange) {
      await approveDealHandlerRequest();
    }

    if (!isDealLevelChange && isLineLevelChange) {
      await updateLineAndApprove();
    } else if (isDealLevelChange) {
      await updateDealAndApprove(catchHandler);
    }
  };

  const rejectDealHandler = async (): Promise<void> => {
    await handleDealRequest(() => rejectDeal(cancelFunctions, { dealId }), dealStatusComplete);
  };

  const cancelDealHandler = async (): Promise<void> => {
    await handleDealRequest(() => cancelDeal(cancelFunctions, { dealId }), dealStatusComplete);
  };

  const reserveDealHandler = (): Promise<void> =>
    handleDealRequest(() => reserveDeal(cancelFunctions, { dealId }), dealStatusComplete);

  const editDealHandler = async (catchHandler: CatchHandler): Promise<void> => {
    const data = {
      ...getDealData(
        {
          ...commonDeal,
          ...programmatic,
          cpm: isCpmCampaignLevel ? cpm : null,
          hasAdsDealLevelCPMEnabled,
        },
        isAdServerMarket,
      ),
      bookingStatusCode: undefined,
    };
    await handleDealRequest(() => editDeal(cancelFunctions, dealId, data), dealStatusComplete, catchHandler);
  };

  const saveDeal = (bookingCode: BookingCode, catchHandler: CatchHandler = () => undefined): void => {
    if (!dealId) {
      submitNewDeal(bookingCode);
      return;
    }

    switch (bookingCode) {
      case DealStatus.PENDING_APPROVAL:
      case DealStatus.APPROVED:
        approveDealHandler(catchHandler);
        break;
      case DealStatus.REJECTED:
        rejectDealHandler();
        break;
      case DealStatus.TERMINATED:
      case DealStatus.CANCELLED:
        cancelDealHandler();
        break;
      case DealStatus.RESERVED:
        reserveDealHandler();
        break;
      default:
        editDealHandler(catchHandler);
    }
  };

  const createLineHandler = async (): Promise<void> => {
    const formData = getLineFormData(commonDeal, { isAdServerMarket }, isCpmCampaignLevel);

    await handleDealRequest(() => createLine(cancelFunctions, formData, { dealId }), lineStatusComplete);
  };

  const updateLineHandler = async (): Promise<void> => {
    const formData = getLineFormData(commonDeal, { isAdServerMarket }, isCpmCampaignLevel);

    await handleDealRequest(
      () =>
        updateLine(cancelFunctions, formData, {
          dealId,
          lineId: currentLine.id,
        }),
      lineStatusComplete,
    );
  };

  const stopLineHandler = async (): Promise<void> => {
    await handleDealRequest(() => stopLine(cancelFunctions, { dealId, lineId: currentLine.id }), lineStatusComplete);
  };

  const deleteLineHandler = async (): Promise<void> => {
    if (!currentLine.lineId) {
      dispatch(changeCurrentLineToLastLine());
      return;
    }

    await handleDealRequest(() => deleteLine(cancelFunctions, { dealId, lineId: currentLine.id }), lineStatusComplete);
  };

  const checkAllocatePollComplete = async ({ campaignID }: Allocation): Promise<void> => {
    try {
      const currentLineAllocationProposal = await getAllocatedDeal(campaignID, cancelFunctions, dealType, lines);

      const summary = combineProposalAllocationAndDealSummary(
        isNewDeal,
        !currentLine.lineId,
        currentLine.preservedAllocation,
        currentLineAllocationProposal,
        commonDeal.summary,
        backupDealSummary,
        dealType,
        hasNonGuaranteedExtendedTargetEnabled,
      );

      dispatch(
        changeDealLineAllocatedImpressions(
          transformAvailability(currentLineAllocationProposal) as Availability & PreservedAvailability,
        ),
      );

      dispatch(
        changeDealData({
          summary,
        }),
      );
      dispatch(changeFormParams({ temporaryDealId: campaignID }));

      let adjustedSot = 0;
      if (currentLineAllocationProposal?.summary?.adjustedSots?.length === 1) {
        [adjustedSot] = currentLineAllocationProposal.summary.adjustedSots;
      }

      dispatch(
        changeDealCurrentLineData({
          deliveredImpressions: currentLineAllocationProposal.deliveredImpressions,
          isCurrentLineWithProposalAllocation: true,
          adjustedSot,
        }),
      );

      dispatch(
        changeDealCurrentLineAvailabilityAssetsSot(
          summary.availability.assets.map((asset) => ({
            frameId: asset.frameId,
            sot: asset.sot,
          })),
        ),
      );

      dispatch(
        notifySuccess({
          message: 'The calculated Qty is not yet reserved. Click Send for approval/Confirm/Save to proceed',
        }),
      );

      dispatch(changeFormParams({ isForecastedAllocation: true }));
    } catch {
      dispatch(clearNotification());
    }

    dispatch(changeFormParams({ isEditingDisabled: false }));
  };

  const checkAllocateHandler = async (): Promise<void> => {
    const formData = getDealWithLineFormData(
      {
        ...commonDeal,
        ...programmatic,
        cpm: isCpmCampaignLevel ? cpm : null,
        bookingStatusCode: DealStatus.PROPOSAL,
        hasAdsDealLevelCPMEnabled,
      },
      { isAdServerMarket },
    );

    await handleDealRequest(() => checkAllocate(cancelFunctions, formData), checkAllocatePollComplete);
  };

  return {
    createLineHandler,
    deleteLineHandler,
    stopLineHandler,
    updateLineHandler,
    checkAllocateHandler,
    saveDeal,
  };
};
