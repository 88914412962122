import { PermissionsEnum } from 'consts/permissions';

export interface Auth {
  init(): Promise<void>;
  getData: () => LocalStoredAuthData;
  redirectToUniversalLoginPage(): Promise<void>;
  isAuthenticated(): Promise<boolean> | boolean;
  hasPermission(permission: PermissionsEnum): boolean;
  authenticate(): Promise<void>;
  logout(): void;
}

export enum AuthProvider {
  Auth0 = 'Auth0',
  Keycloak = 'Keycloak',
}

export type LocalStoredAuthData = {
  userName?: string;
  email?: string;
  token?: string;
  markets: string[];
  permissions: string[];
};
