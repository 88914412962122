import { NavigateFunction } from 'react-router-dom';
import { BannerType } from 'lib/Banner';
import {
  CampaignVersioningMessageExtendedMapping,
  CampaignVersioningEditingStatus,
  CampaignVersioningErrors,
} from 'constants/directSalesCampaignVersioning';

export const getCampaignVersioningMessageExtended = (
  navigate: NavigateFunction,
  discardChanges: (campaignId: string) => Promise<void>,
  confirmChanges: (campaignId: string) => Promise<void>,
  retry: () => Promise<void>,
  isConfirmDisabled: boolean,
): CampaignVersioningMessageExtendedMapping => ({
  [CampaignVersioningEditingStatus.IN_PROGRESS]: {
    type: BannerType.INFO,
    headerInfo: 'Commit changes?',
    contentInfo: 'This will re-run an allocation for your entire campaign',
    discardButton: {
      buttonLabel: 'Discard',
      actionCallback: discardChanges,
    },
    confirmButton: {
      buttonLabel: 'Commit',
      actionCallback: confirmChanges,
      isDisabled: isConfirmDisabled,
    },
  },
  [CampaignVersioningErrors.TECHNICAL_ERROR]: {
    type: BannerType.ERROR,
    headerInfo: 'Changes not commited',
    contentInfo: 'This action failed due to a technical error',
    discardButton: {
      buttonLabel: 'Discard',
      actionCallback: discardChanges,
    },
    confirmButton: {
      buttonLabel: 'Retry',
      actionCallback: retry,
      isDisabled: isConfirmDisabled,
    },
  },
  [CampaignVersioningErrors.TECHNICAL_ERROR_REALLOCATE]: {
    type: BannerType.ERROR,
    headerInfo: 'Changes not commited',
    contentInfo: 'This action failed due to a technical error. Please recheck allocation or retry commit',
    discardButton: {
      buttonLabel: 'Discard',
      actionCallback: discardChanges,
    },
    confirmButton: {
      buttonLabel: 'Retry',
      actionCallback: retry,
      isDisabled: isConfirmDisabled,
    },
  },
  [CampaignVersioningErrors.ERROR_LIVE]: {
    type: BannerType.ERROR,
    headerInfo: 'Changes not commited',
    contentInfo: 'This action failed due to editing live campaign.',
  },
  [CampaignVersioningErrors.DRAFT_FAILED_PRICING]: {
    type: BannerType.WARNING,
    headerInfo: 'Failure calculating price',
    contentInfo: 'Changes have been saved but pricing cannot be calculated at this time. Support has been notifed',
  },
  [CampaignVersioningErrors.CAMPAIGN_CORRUPTED]: {
    type: BannerType.ERROR,
    headerInfo: 'Campaign corrupted due to replacement failure',
    contentInfo:
      'This solution has been saved, however this campaign has been corrupted and no further action is possible. Support has been notifed',
    exitButton: {
      buttonLabel: 'Exit',
      actionCallback: () => navigate('/deals'),
    },
  },
  [CampaignVersioningErrors.OPTION_DATE_REQUIRED]: {
    type: BannerType.WARNING,
    headerInfo: 'Option drop date required',
    contentInfo:
      'To commit these changes the deal line needs an option drop date. Please add under status and recommit',
    discardButton: {
      buttonLabel: 'Discard',
      actionCallback: (campaignId: string) => discardChanges(campaignId),
    },
    confirmButton: {
      buttonLabel: 'Commit',
      actionCallback: (campaignId: string) => confirmChanges(campaignId),
      isDisabled: isConfirmDisabled,
    },
  },
  [CampaignVersioningErrors.STATUS_REQUIRED]: {
    type: BannerType.WARNING,
    headerInfo: 'Status required',
    contentInfo: 'To commit these changes all deal lines need a status. Please add and recommit',
    discardButton: {
      buttonLabel: 'Discard',
      actionCallback: (campaignId: string) => discardChanges(campaignId),
    },
    confirmButton: {
      buttonLabel: 'Commit',
      actionCallback: (campaignId: string) => confirmChanges(campaignId),
      isDisabled: isConfirmDisabled,
    },
  },
  [CampaignVersioningErrors.FAILURE_PRICE]: {
    type: BannerType.WARNING,
    headerInfo: 'Failure calculating price',
    contentInfo: 'Solution was successful, but pricing of solution was a failure, please check-allocate again',
  },
  [CampaignVersioningErrors.FAILURE_SOLVE]: {
    type: BannerType.WARNING,
    headerInfo: 'Solution Failure',
    contentInfo: 'Solution was NOT successful, please check-allocate again',
    discardButton: {
      buttonLabel: 'Discard',
      actionCallback: (campaignId: string) => discardChanges(campaignId),
    },
  },
});
