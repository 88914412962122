import { useRef } from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import arrowDown from 'assets/icons/arrow_light_down.svg';
import check from 'assets/icons/check.svg';
import { useToggle } from 'customHooks/useToggle';
import { useOutsideClick } from 'customHooks/useOutsideClick';
import { IconSize } from 'components/common/types/Input.types';
import { Color, ButtonTheme } from 'lib/Button/Button.types';
import { DropdownMenuPosition, DropdownMenuProps, dropdownMenuPositions } from './DropdownMenu.types';

const IconStyles: {
  size: Record<IconSize.MEDIUM | IconSize.LARGE, string>;
} = {
  size: {
    [IconSize.LARGE]: 'w-6 h-6',
    [IconSize.MEDIUM]: 'w-4 h-4',
  },
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  dropdownMenuOptions,
  icon,
  iconSize = IconSize.MEDIUM,
  label,
  dropdownMenuPosition = DropdownMenuPosition.RIGHT,
  color = Color.PRIMARY,
  className,
  isDisabled = false,
}) => {
  const splitButtonRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { isOpen, toggle, close } = useToggle(false);

  useOutsideClick(splitButtonRef, close);

  return (
    <div className="inline-flex relative" ref={splitButtonRef}>
      <button
        className={cx(
          'flex gap-2 items-center sub-header-base px-4 py-2.5 rounded-md disabled:cursor-not-allowed',
          ButtonTheme.theme[color],
          className,
        )}
        onClick={toggle}
        type="button"
        data-test-id="selected-option"
        disabled={isDisabled}
      >
        {icon ? (
          <div data-test-id="button-icon">
            <SVG src={icon} className={IconStyles.size[iconSize]} />
          </div>
        ) : null}
        {label}
        <div data-test-id="arrow-icon">
          <SVG src={arrowDown} className="w-4 h-4" />
        </div>
      </button>
      {isOpen && (
        <div
          className={`flex flex-col space-y-1 absolute top-9 ${dropdownMenuPositions[dropdownMenuPosition]} py-3 px-2 mt-[1px] bg-neutral-50 shadow-md rounded-md w-60`}
          data-test-id="options"
        >
          {dropdownMenuOptions.map((buttonOption) => (
            <button
              className="flex gap-2 items-center body-base text-neutral-950 px-4 py-2 rounded-md hover:bg-primary-600-opacity-10"
              key={buttonOption.id}
              onClick={buttonOption.onClick}
              type="button"
              data-pendo-id={buttonOption.dataPendoId || null}
            >
              {buttonOption.icon ? (
                <div data-test-id={`option-icon-${buttonOption.label}`}>
                  <SVG src={buttonOption.icon} className="w-4.5 h-4.5" />
                </div>
              ) : null}
              {buttonOption.label}
              {buttonOption.isSelected ? (
                <div data-test-id={`option-checkmark-icon-${buttonOption.label}`} className="ml-auto">
                  <SVG src={check} className="text-primary-600 w-4 h-4" />
                </div>
              ) : null}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
