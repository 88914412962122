import cx from 'classnames';
import IconButton from 'lib/IconButton';
import Button, { Color, Size as SizeButton } from 'lib/Button';
import sidebarSimpleRightIcon from 'assets/icons/sidebar-simple-right.svg';
import mapIcon from 'assets/icons/map.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { togglePlannerMap } from 'store/dealManagement/reducer';
import { FrontEndType } from 'components/common/types/Deal.types';
// import locationsIcon from 'assets/icons/locations.svg';

const CampaignActionButtons: React.FC = () => {
  const dispatch = useDispatch();

  const isPlannerMapVisible = useSelector((state: Store) => state.dealManagement.isPlannerMapVisible);
  const frontEndType = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.frontEndType);

  return (
    <div
      data-test-id="action-buttons"
      className="flex flex-shrink-0 gap-1 px-[8px] justify-end items-center border-l border-neutral-950-opacity-10 w-[var(--right-sidebar-width)]"
    >
      {/* Hidden until switching view functionality is enabled */}
      {/* <Button color={Color.BLACK_AND_WHITE} size={SizeButton.SMALL} svg={locationsIcon} label="Open in planner" /> */}

      {frontEndType === FrontEndType.PLANNER && (
        <Button
          color={isPlannerMapVisible ? Color.TERTIARY : Color.BLACK_AND_WHITE}
          size={SizeButton.SMALL}
          svg={mapIcon}
          label="Map"
          classNames={cx('border leading-tight', {
            'border-primary-600': isPlannerMapVisible,
          })}
          svgClassnames="!w-3 !h-3"
          onClick={() => {
            dispatch(togglePlannerMap(!isPlannerMapVisible));
          }}
        />
      )}

      <IconButton icon={sidebarSimpleRightIcon} name="Toggle right sidebar" />
    </div>
  );
};

export default CampaignActionButtons;
