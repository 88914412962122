import { useSelector } from 'react-redux';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import Header from 'components/common/Header';
import SubNavigation from 'components/common/SubNavigation';
import { FeatureFlags } from 'components/common/types/Features.types';
import { Store } from 'components/common/types/Store.types';
import { LabsReportDropdown } from '../LabsReportDropdown/LabsReportDropdown';
import LabsSwitch from '../LabsSwitch';

const InsightsHeader: React.FC = () => {
  const showNewInsightsPage = useSelector((state: Store) => state.header.showNewInsightsPage);
  const isNewInsightsPage = useHasFeatureAccess(FeatureFlags.NEW_INSIGHTS_PAGE) && showNewInsightsPage;

  const hasPacingDashboardAccess = useHasFeatureAccess(FeatureFlags.PACING_DASHBOARD);
  const hasInsightsLabAccess = useHasFeatureAccess(FeatureFlags.INSIGHTS_LABS);
  const hasProofOfPlayAccess = useHasFeatureAccess(FeatureFlags.PROOF_OF_PLAY_LABS);
  const hasYieldInsightsAccess =
    Auth.hasPermission(PermissionsEnum.INSIGHTS_YIELD_VIEW) && useHasFeatureAccess(FeatureFlags.YIELD_INSIGHTS_LABS);

  const isLabsDropdownVisible =
    (useHasFeatureAccess(FeatureFlags.INSIGHTS_DEAL_HEALTH_LABS) || hasProofOfPlayAccess || hasYieldInsightsAccess) &&
    (useHasFeatureAccess(FeatureFlags.NEW_INSIGHTS_PAGE) ? !showNewInsightsPage : true);

  const thoughtspotInsightsTabs = [
    {
      id: 1,
      path: '/insights/deal-health',
      isVisible: true,
      name: 'Deal health',
    },
    {
      id: 2,
      path: '/insights/proof-of-play',
      isVisible: hasProofOfPlayAccess,
      name: 'Proof of play',
    },
    {
      id: 3,
      path: '/insights/yield-insights',
      isVisible: hasYieldInsightsAccess,
      name: 'Yield insights',
    },
  ];

  const nativeInsightsTabs = [
    {
      id: 1,
      path: '/insights/dashboard',
      isVisible: true, // TODO: Permission TBD
      name: 'Deal dashboard',
    },
    {
      id: 2,
      path: '/insights/pacing',
      isVisible: hasPacingDashboardAccess,
      name: 'Pacing',
    },
    {
      id: 3,
      path: '/insights/deal-performance',
      isVisible: Auth.hasPermission(PermissionsEnum.DEAL_LEVEL_PERFORMANCE_PAGE_ACCESS),
      name: 'Performance',
    },
    {
      id: 4,
      path: '/insights/revenue',
      isVisible: true, // TODO: Permission TBD
      name: 'Revenue',
    },
    {
      id: 5,
      path: '/insights/diagnostics',
      isVisible: Auth.hasPermission(PermissionsEnum.DIAGNOSTICS_PAGE_ACCESS),
      name: 'Diagnostics',
    },
    {
      id: 6,
      path: '/insights/labs',
      isVisible: hasInsightsLabAccess,
      name: 'Labs',
    },
  ];

  return (
    <Header
      title="Insights"
      subNavigation={<SubNavigation tabsConfig={isNewInsightsPage ? thoughtspotInsightsTabs : nativeInsightsTabs} />}
      actionButton={isLabsDropdownVisible ? <LabsReportDropdown /> : null}
      labsSwitch={useHasFeatureAccess(FeatureFlags.NEW_INSIGHTS_PAGE) ? <LabsSwitch /> : null}
    />
  );
};

export default InsightsHeader;
