import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'customHooks/useToggle';
import { changePlannerSecondaryPanelType, PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { updateIsObjectiveSubsectionChange } from 'store/objective/reducer';
import { UseNotification } from './useNotification.types';

const useNotification = (isChange: boolean): UseNotification => {
  const dispatch = useDispatch();

  const { isOpen, open: openNotification, close: closeNotification } = useToggle();

  const handleDiscard = (): void => {
    dispatch(changePlannerSecondaryPanelType(PlannerSecondaryPanelType.OBJECTIVE));
    dispatch(updateIsObjectiveSubsectionChange(false));
  };

  const handleBackNavigation = useCallback((): void => {
    if (isChange) openNotification();
    else handleDiscard();
  }, [isChange]);

  useEffect(() => {
    if (!isChange) closeNotification();
  }, [isChange]);

  return { closeNotification, handleBackNavigation, handleDiscard, isOpen };
};

export default useNotification;
