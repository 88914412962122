import { Color } from '../Button';

export enum AlertThemeOption {
  PRIMARY = 'PRIMARY',
  YELLOW = 'YELLOW',
  PINK_RED = 'PINK_RED',
  LIGHT_PINK_RED = 'LIGHT_PINK_RED',
}

export const AlertTheme = {
  [AlertThemeOption.PRIMARY]: {
    base: 'bg-primary-600 text-white',
    buttonColor: Color.SECONDARY,
  },
  [AlertThemeOption.YELLOW]: {
    base: 'bg-yellow-200 text-yellow-950',
    buttonColor: Color.SECONDARY,
  },
  [AlertThemeOption.PINK_RED]: {
    base: 'bg-pinkRed-200 text-pinkRed-950',
    buttonColor: Color.DANGER,
  },
  [AlertThemeOption.LIGHT_PINK_RED]: {
    base: 'bg-pinkRed-50 text-neutral-950',
    buttonColor: Color.PRIMARY,
  },
};

export interface AlertProps {
  onCancel: () => void;
  onSave: () => void;
  onClose?: () => void;
  dataTestId?: string;
  icon: string;
  header: string;
  description: string;
  theme?: AlertThemeOption;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
}
