import { format, isSameWeek } from 'date-fns';
import { DayPart, GroupOfSelectedDays, SelectedDay } from 'components/common/types/Deal.types';
import {
  DirectSalesCampaignDealLineDaypartPattern,
  DirectSalesCampaignDealLineRangePattern,
  DirectSalesDaypartDurationType,
  DirectSalesCampaignDealLineDaypartDuration,
} from 'components/common/types/DirectSalesCampaign.types';
import {
  SECONDS_IN_AN_HOUR,
  END_OFFSET_ADJUSTMENT,
  DAYS_IN_WEEK,
  WEEKS_DAYS_ID,
  UPPERCASE_DAYS_OF_THE_WEEK,
  LAST_DAY_INDEX,
  FIRST_DAY_INDEX,
} from 'components/common/DaypartTargeting/constants';
import { getDaypartHoursIndexes } from 'components/common/DaypartTargeting/getDaypartHoursIndexes';
import { PatternOption } from 'components/common/DaypartTargeting/DaypartTargeting.types';
import { getStartDayId } from 'components/common/DaypartTargeting/utils';

export const transformWeekRangePattern = (
  selectedDays: SelectedDay[],
  weekId: number,
  isRepeated: boolean,
): DirectSalesCampaignDealLineDaypartPattern[] => {
  if (!selectedDays.length) return [];

  const getDayIdBasedOnWeekStart = (dayId: number): number => dayId + 1;

  const transformedFullDays = [
    {
      week: weekId,
      days: [''],
      durations: [
        {
          type: DirectSalesDaypartDurationType.DAY,
          range: [],
        },
      ],
    },
  ];

  const fullDays = Object.values(selectedDays).filter(({ isFullDay }) => isFullDay);

  transformedFullDays[0].days = fullDays.map(({ dayId, date }) => {
    const dayIndex = isRepeated && dayId !== undefined ? getDayIdBasedOnWeekStart(dayId) : date.getDay();
    return UPPERCASE_DAYS_OF_THE_WEEK[dayIndex % DAYS_IN_WEEK];
  });

  const partDays = Object.values(selectedDays).filter(({ isFullDay }) => !isFullDay);

  const transformedPartDays = partDays.map(({ dayId, dayParts, date }) => {
    const durationRange = dayParts.reduce<number[]>((hourIndexes, hour) => {
      const indexes = getDaypartHoursIndexes(hour.startOffset, hour.endOffset);

      hourIndexes.push(...indexes);

      return hourIndexes;
    }, []);
    const dayIndex = isRepeated && dayId !== undefined ? getDayIdBasedOnWeekStart(dayId) : date.getDay();
    const days = [UPPERCASE_DAYS_OF_THE_WEEK[dayIndex % DAYS_IN_WEEK]];

    return {
      week: weekId,
      days,
      durations: [
        {
          type: 'HOUR',
          range: [{ hours: durationRange }],
        },
      ],
    };
  });

  return fullDays.length >= 1 ? [...transformedFullDays, ...transformedPartDays] : [...transformedPartDays];
};

export const splitSelectedDaysIntoGroupsOfWeeks = (
  selectedDays: GroupOfSelectedDays,
  isRepeated: boolean = false,
  patternLength: number = 0,
): SelectedDay[][] => {
  const WEEK_START_ON = 1;
  const days = Object.values(selectedDays);

  if (!days.length) return [];

  if (patternLength === 1 || days.length < DAYS_IN_WEEK) {
    return [days];
  }

  if (isRepeated) {
    return days.reduce((acc: SelectedDay[][], currentDay: SelectedDay) => {
      const weekIndex = Math.floor((currentDay?.dayId || 0) / DAYS_IN_WEEK);

      if (!acc[weekIndex]) acc[weekIndex] = [];

      acc[weekIndex].push(currentDay);

      return acc;
    }, []);
  }

  return days.reduce((weeks: SelectedDay[][], day, index) => {
    if (index === 0) {
      return [[day]];
    }

    const lastWeek = weeks[weeks.length - 1];
    const lastDayInWeek = lastWeek[lastWeek.length - 1];

    if (isSameWeek(lastDayInWeek.date, day.date, { weekStartsOn: WEEK_START_ON })) {
      lastWeek.push(day);
    } else {
      weeks.push([day]);
    }

    return weeks;
  }, []);
};

export const transformRangePattern = (
  selectedDays: GroupOfSelectedDays,
  isRepeated: boolean = false,
  patternLength: number,
): DirectSalesCampaignDealLineRangePattern => {
  const groupsOfWeeks = splitSelectedDaysIntoGroupsOfWeeks(selectedDays, isRepeated, patternLength);

  const groupOfWeekPatterns = groupsOfWeeks.map((group: SelectedDay[], index: number) =>
    transformWeekRangePattern(group, index + 1, isRepeated),
  );

  const patterns = groupOfWeekPatterns.length ? groupOfWeekPatterns.flat() : [];

  return {
    length: isRepeated ? patternLength : groupsOfWeeks.length,
    patterns,
  };
};

export const groupHoursIntoTimeBlocks = (hours: number[]): DayPart[] => {
  return hours.reduce((timeBlocks: DayPart[], hour: number, index: number) => {
    const startOffset = hour * SECONDS_IN_AN_HOUR;
    const endOffset = startOffset + END_OFFSET_ADJUSTMENT;

    const isNewTimeBlock = index === 0 || hours[index - 1] !== hour - 1;

    if (isNewTimeBlock) {
      timeBlocks.push({
        id: -2,
        dayPartId: 0,
        displayName: '',
        startOffset,
        endOffset,
      });
    } else {
      timeBlocks[timeBlocks.length - 1].endOffset = endOffset;
    }

    return timeBlocks;
  }, []);
};

export const getDayparts = (durations: DirectSalesCampaignDealLineDaypartDuration[]): DayPart[] => {
  return durations.flatMap((duration) => {
    if (duration.type === DirectSalesDaypartDurationType.DAY) {
      return {
        id: -1,
        dayPartId: -1,
        startOffset: 0,
        endOffset: 86399,
        displayName: 'Full Day',
      };
    }

    return groupHoursIntoTimeBlocks(duration.range[0].hours);
  });
};

export const getDayEntry = ({
  dayId,
  date,
  dateString,
  dayParts,
  isFullDay,
}: {
  dayId: number;
  date: Date;
  dateString: string;
  dayParts: DayPart[];
  isFullDay: boolean;
}): SelectedDay => ({
  dayId,
  currentSelection: false,
  dayParts,
  date,
  id: dateString,
  isFullDay,
  tempCustomHours: {},
});

type WeekEndStart = { week: number; start: Date; end: Date };

export const getStartOfWeek = (date: Date): Date => {
  const newDate = new Date(date);
  const MONDAY_DATE_INDEX = 1;
  const adjustStartToMonday = date.getDate() - date.getDay() + MONDAY_DATE_INDEX;

  newDate.setDate(adjustStartToMonday);
  newDate.setHours(0, 0, 0, 0);

  return newDate;
};

export const getEndOfWeek = (date: Date): Date => {
  const newDate = new Date(date);
  const SUNDAY_DATE_INDEX = 7;
  const adjustEndToSunday = date.getDate() - date.getDay() + SUNDAY_DATE_INDEX;

  newDate.setDate(adjustEndToSunday);
  newDate.setHours(23, 59, 59, 999);

  return newDate;
};

export const splitResponseIntoGroupsOfWeeks = (start: Date, end: Date): WeekEndStart[] => {
  const weeks = [];
  let currentStart = getStartOfWeek(start);
  let currentEnd = getEndOfWeek(currentStart);

  if (currentStart < start) {
    currentStart = new Date(start);
  }

  let currentWeek = 1;
  while (currentStart <= end) {
    const weekEnd = currentEnd > end ? end : currentEnd;

    weeks.push({
      week: currentWeek,
      start: currentStart,
      end: weekEnd,
    });

    currentStart = new Date(currentEnd);
    currentStart.setDate(currentStart.getDate() + 1);
    currentEnd = getEndOfWeek(currentStart);

    currentWeek++;
  }

  return weeks;
};

export const transformNoRepeatPattern = ({
  start,
  end,
  patterns,
}: {
  start: Date;
  end: Date;
  patterns: DirectSalesCampaignDealLineDaypartPattern[];
}): GroupOfSelectedDays => {
  const startDayIndex = getStartDayId(start) - 1;

  let dayId = startDayIndex;

  const weeks = splitResponseIntoGroupsOfWeeks(start, end);

  const daysArray: GroupOfSelectedDays[] = [];

  weeks.forEach((week, index) => {
    const currentStart = week.start;
    const currentEndWithTime = week.end;
    currentEndWithTime.setHours(23, 59, 59, 999);

    while (currentStart <= currentEndWithTime) {
      const dayOfWeek = format(currentStart, 'EEEE').toUpperCase();
      const currentDate = new Date(currentStart);
      const currentDateString = format(currentStart, "yyyy-MM-dd'T'00:00:00.000'Z'");

      const weekNumber = index + 1;

      const matchingPattern = patterns.find(
        (pattern) => pattern.week === weekNumber && pattern.days.includes(dayOfWeek),
      );

      dayId++;

      if (matchingPattern) {
        const isFullDay = matchingPattern.durations[0].type === DirectSalesDaypartDurationType.DAY;

        const dayParts = getDayparts(matchingPattern.durations);

        daysArray.push({
          [currentDateString]: getDayEntry({
            dayId,
            date: currentDate,
            dateString: currentDateString,
            dayParts,
            isFullDay,
          }),
        });
      }

      currentStart.setDate(currentStart.getDate() + 1);
    }
  });

  return daysArray.reduce((acc, dayEntry) => ({ ...acc, ...dayEntry }), {});
};

const moveToNextMonday = (date: Date): Date => {
  while (date.getDay() !== 1) {
    date.setDate(date.getDate() + 1);
  }

  return date;
};

const getCurrentWeekId = (index: number): number | null => {
  const weekId = Object.keys(WEEKS_DAYS_ID)
    .map(Number)
    .find((key: keyof typeof WEEKS_DAYS_ID) => {
      const [start, end] = WEEKS_DAYS_ID[key];
      return index >= start && index <= end;
    });

  return weekId ?? null;
};

export const transformRepeatedPattern = ({
  startDate,
  startIndex = FIRST_DAY_INDEX,
  endIndex = LAST_DAY_INDEX,
  patterns,
}: {
  startDate: string;
  startIndex: number;
  endIndex: number;
  patterns: DirectSalesCampaignDealLineDaypartPattern[];
}): GroupOfSelectedDays => {
  const daysArray = [];

  const currentDate = moveToNextMonday(new Date());

  const dealLineStartDate = new Date(startDate);

  for (let index = startIndex; index <= endIndex; index++) {
    const currentWeekId = getCurrentWeekId(index);
    const dayOfWeek = format(currentDate, 'EEEE').toUpperCase();
    const currentDateString = format(dealLineStartDate, "yyyy-MM-dd'T'00:00:00.000'Z'");

    const weekPatterns = patterns.filter(({ week }) => week === currentWeekId);

    const matchingWeek = weekPatterns.find((week) => week.days.includes(dayOfWeek));

    if (matchingWeek) {
      const isFullDay = matchingWeek.durations[0].type === DirectSalesDaypartDurationType.DAY;

      const dayParts = getDayparts(matchingWeek.durations);

      daysArray.push({
        [daysArray.length]: getDayEntry({
          dayId: daysArray.length,
          date: dealLineStartDate,
          dateString: currentDateString,
          dayParts,
          isFullDay,
        }),
      });
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return daysArray.reduce((acc, dayEntry) => ({ ...acc, ...dayEntry }), {});
};

export const reverseTransformDaypartTargeting = (
  rangePattern: DirectSalesCampaignDealLineRangePattern,
  startDate: string,
  endDate: string,
  patternRepeatType: PatternOption,
): GroupOfSelectedDays => {
  const { patterns } = rangePattern;
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (patternRepeatType === PatternOption.REPEAT_ONE_WEEK || patternRepeatType === PatternOption.REPEAT_TWO_WEEKS) {
    const oneWeek = { startDate, startIndex: 0, endIndex: 6, patterns };
    const twoWeeks = { startDate, startIndex: 0, endIndex: 13, patterns };

    return transformRepeatedPattern(patternRepeatType === PatternOption.REPEAT_ONE_WEEK ? oneWeek : twoWeeks);
  }

  return transformNoRepeatPattern({ start, end, patterns });
};
