import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PermissionsEnum } from 'consts/permissions';
import { PageWrapper } from 'components/common/layout/PageWrapper/PageWrapper';
import PrivateRoute from 'components/common/Auth/Private';
import { ChildRoute } from 'components/common/types/Route.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { Store } from 'components/common/types/Store.types';
import InsightsHeader from './InsightsHeader';
import DealDashboard from './DealDashboard';
import Performance from './Performance';
import Revenue from './Revenue';
import Diagnostics from './Diagnostics';
import Pacing from './Pacing';
import { Labs } from './Labs/Labs';
import DealHealth from './DealHealth';
import ProofOfPlay from './ProofOfPlay';
import YieldInsights from './YieldInsights';

const Insights: React.FC = () => {
  const showNewInsightsPage = useSelector((state: Store) => state.header.showNewInsightsPage);
  const isNewInsightsPage = useHasFeatureAccess(FeatureFlags.NEW_INSIGHTS_PAGE) && showNewInsightsPage;

  const childRoutes: ChildRoute[] = isNewInsightsPage
    ? [
        {
          id: 1,
          path: `deal-health`,
          main: DealHealth,
          pageAccessPermission: undefined,
        },
        {
          id: 2,
          path: `proof-of-play`,
          main: ProofOfPlay,
          pageAccessPermission: undefined,
        },
        {
          id: 3,
          path: `yield-insights`,
          main: YieldInsights,
          pageAccessPermission: PermissionsEnum.INSIGHTS_YIELD_VIEW,
        },
        {
          id: 4,
          path: '*',
          main: () => <Navigate to="deal-health" />,
          pageAccessPermission: undefined,
        },
      ]
    : [
        {
          id: 1,
          path: `dashboard`,
          main: DealDashboard,
          pageAccessPermission: undefined,
        },
        {
          id: 2,
          path: `pacing/*`,
          main: Pacing,
          pageAccessPermission: undefined,
        },
        {
          id: 3,
          path: `deal-performance`,
          main: Performance,
          pageAccessPermission: PermissionsEnum.DEAL_LEVEL_PERFORMANCE_PAGE_ACCESS,
        },
        {
          id: 4,
          path: `diagnostics`,
          main: Diagnostics,
          pageAccessPermission: PermissionsEnum.DIAGNOSTICS_PAGE_ACCESS,
        },
        {
          id: 5,
          path: `revenue`,
          main: Revenue,
          pageAccessPermission: undefined,
        },
        {
          id: 6,
          path: `labs`,
          main: Labs,
          pageAccessPermission: undefined,
        },
        {
          id: 7,
          path: '*',
          main: () => <Navigate to="dashboard" />,
          pageAccessPermission: undefined,
        },
      ];

  return (
    <PageWrapper>
      <InsightsHeader />
      <Routes>
        {childRoutes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={<PrivateRoute component={route.main} pageAccessPermission={route.pageAccessPermission} />}
          />
        ))}
      </Routes>
    </PageWrapper>
  );
};

export default Insights;
