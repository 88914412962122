import { IconSize } from 'components/common/types/Input.types';
import { Color } from 'lib/Button';

export enum DropdownMenuPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface DropdownMenuOption {
  id: string;
  label: string;
  onClick: () => void;
  dataPendoId?: string;
  icon?: string;
  isSelected?: boolean;
}

export interface DropdownMenuProps {
  dropdownMenuOptions: DropdownMenuOption[];
  dropdownMenuPosition?: DropdownMenuPosition;
  icon?: string;
  iconSize?: IconSize.MEDIUM | IconSize.LARGE;
  label: string;
  color?: Color;
  className?: string;
  isDisabled?: boolean;
}

export const dropdownMenuPositions: Record<DropdownMenuPosition, string> = {
  [DropdownMenuPosition.LEFT]: 'left-0',
  [DropdownMenuPosition.RIGHT]: 'right-0',
};
