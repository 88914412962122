import config from 'config';
import Auth0 from './Auth0';
import Keycloak from './Keycloak';
import { Auth, AuthProvider } from './Auth.types';

const setAuthAdaptor = (provider: AuthProvider): Auth => {
  if (provider === AuthProvider.Keycloak) {
    return Keycloak;
  }
  return Auth0;
};

export default setAuthAdaptor(config.authProvider as AuthProvider);
