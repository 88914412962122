import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';

export interface MandatoryFieldsCheckResult {
  isAllocationTabEnabled: boolean;
  areDealInfoMandatoryFieldsFilled: boolean;
  isDataFiltersTabEnabled: boolean;
  areAllocationMandatoryFieldsFilled: boolean;
  areAllMandatoryFieldsFilled: boolean;
}

export const useCheckDirectSalesMandatoryFields = (): MandatoryFieldsCheckResult => {
  const mediaType = useSelector((state: Store) => state.dealManagement.directSales.mediaType);
  const advertiser = useSelector((state: Store) => state.dealManagement.commonDeal.advertiser);
  const brand = useSelector((state: Store) => state.dealManagement.commonDeal.brand);
  const productCategory = useSelector((state: Store) => state.dealManagement.commonDeal.productCategory);
  const startDate = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.startDate);
  const endDate = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.endDate);
  const environments = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.environments);
  const impressions = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.impressions);
  const budget = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.budget);
  const frames = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.frames);
  const name = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.name);

  const isDataFiltersTabEnabled = false;

  const areDealInfoMandatoryFieldsFilled = useMemo(() => {
    return Object.values({
      startDate,
      endDate,
      advertiser,
      brand,
      productCategory,
      haveEnvironments: environments.length > 0,
      mediaType,
      name,
    }).every(Boolean);
  }, [startDate, endDate, advertiser, brand, productCategory, environments, mediaType, name]);

  const areAllocationMandatoryFieldsFilled = useMemo(() => {
    return Object.values({
      impressionsOrBudgetOrFrames: Boolean(impressions || budget || frames),
    }).every(Boolean);
  }, [impressions, budget, frames]);

  const areAllMandatoryFieldsFilled = useMemo(() => {
    return Object.values({
      areDealInfoMandatoryFieldsFilled,
      areAllocationMandatoryFieldsFilled,
    }).every(Boolean);
  }, [areDealInfoMandatoryFieldsFilled, areAllocationMandatoryFieldsFilled]);

  return {
    areDealInfoMandatoryFieldsFilled,
    isAllocationTabEnabled: areDealInfoMandatoryFieldsFilled,
    isDataFiltersTabEnabled,
    areAllocationMandatoryFieldsFilled,
    areAllMandatoryFieldsFilled,
  };
};
