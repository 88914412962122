import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { notifyError, notifyInfo, notifySuccess } from 'store/notification/reducer';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { hideBanner, showBanner } from 'store/banner/reducer';
import {
  createDirectSalesCampaign,
  deleteDirectSalesDealLine,
  editDirectSalesCampaign,
  getDirectSalesCampaign,
  getDirectSalesSolution,
  pollDealLineState,
  pollDealSalesCampaign,
  updateDealLineState,
  updateDirectSalesDealLine,
  updateTransientDealState,
} from 'modules/api/directSalesCampaign';
import {
  CampaignVersioningEditingStatus,
  CampaignVersioningErrors,
  ErrorDetailType,
  mapCampaignVersioningErrors,
} from 'consts/directSalesCampaignVersioning';
import { getCampaignVersioningMessageExtended } from 'components/pages/Planner/hooks/utils/getCampaignVersioningMessagesExtended';
import { Store } from 'components/common/types/Store.types';
import {
  addDirectSalesLineId,
  changeDealCurrentLineData,
  changeDealData,
  changeDirectSalesData,
  changeFormParams,
  clearDirectSalesAllocation,
  clearTransientLineStates,
  generateUniqueDealLineName,
  hideDisclaimer,
  hidePlannerSecondaryPanel,
  removeDirectSalesLine,
  setLoadedDirectSalesData,
  updateBackupFormDataLine,
  updateDirectSalesSessionLock,
} from 'store/dealManagement/reducer';
import { Advertiser, Brand, Line } from 'components/common/types/Deal.types';
import { CodeNameModel } from 'components/common/types';
import {
  DirectSales,
  DirectSalesIntendedDealLineState,
  DirectSalesLine,
  DirectSalesState,
  DirectSalesStatusOption,
  DirectSalesStatusOptionEvent,
  DirectSalesTransientStates,
} from 'components/common/types/DirectSalesCampaign.types';
import { getDateInRequestFormat, getTimeInDate } from 'utils/dateUtil';
import {
  DirectSalesCampaignDealLineStateResponse,
  DirectSalesCampaignResponse,
} from 'components/common/types/DirectSalesCampaignResponse.types';
import { isEmpty } from 'lodash';
import { clearActiveObjective } from 'store/objective/reducer';
import {
  isTransientCommitDisabled,
  isValidAttemptToConfirmChanges,
  mapDealLineStatesToEventState,
} from 'components/pages/Planner/hooks/utils/directSalesTransientLineUtils';
import { useCheckDirectSalesLineLevelChange } from './useCheckDirectSalesLineLevelChange';
import { useCheckDirectSalesMandatoryFields } from './useCheckDirectSalesMandatoryFields';
import useCommonPlannerActions from './useCommonPlannerActions';
import {
  mergeCurrentLineToAllLines,
  transformAllLineData,
  transformDirectSalesBookingResponse,
  transformDirectSalesDealLineRequest,
  transformDirectSalesWithLineFormRequest,
  TransformedDirectSalesBookingResponse,
} from './utils/transformDirectSalesCampaign';
import { useCheckDirectSalesCampaignLevelChange } from './useCheckDirectSalesCampaignLevelChanges';
import { isDirectSalesCampaignCloned } from './utils/isDirectSalesCampaignCloned';
import { isDirectSalesLinePriced } from './utils/getIsDirectSalesLinePriced';

type GetUpdatedDirectSalesCampaignResult = Promise<DirectSalesCampaignResponse & TransformedDirectSalesBookingResponse>;

const STATUS_PROCESS_END_STATUSES = [
  DirectSalesStatusOption.OPTION,
  DirectSalesStatusOption.CONFIRMED,
  DirectSalesStatusOption.CANCELLED,
  DirectSalesStatusOption.PROPOSAL,
  DirectSalesStatusOption.DRAFT_PRICED,
  DirectSalesStatusOption.DRAFT_FAILED_PRICE,
  DirectSalesStatusOption.DRAFT_FAILED_SOLVE,
];

interface UseDirectSalesPlannerActions {
  checkAllocateHandler: () => void;
  getUpdatedDirectSalesCampaign: (customCampaignId?: string) => GetUpdatedDirectSalesCampaignResult;
  statusConditions: {
    showSave: boolean;
    showConfirm: boolean;
    showSendForApproval: boolean;
  };
  isSavingDisabled: boolean;
  saveDeal: () => Promise<void>;
  getSolution: (pollCompleteHandler: () => Promise<void>) => Promise<void>;
  editDeal: (data?: { currentLineData?: Partial<Line>; directSalesData?: Partial<DirectSales> }) => Promise<void>;
  editDealLine: (data?: { currentLineData?: Partial<Line>; directSalesData?: Partial<DirectSales> }) => Promise<void>;
  saveDealLineState: (state: DirectSalesState, pollCompleteHandler: () => Promise<void>) => Promise<void>;
  deleteDealLine: (lineId: string, callback?: VoidFunction) => Promise<void>;
  discardChanges: (providedCampaignId: string) => Promise<void>;
  showBannerInformation: (
    bannerInformation: CampaignVersioningErrors | CampaignVersioningEditingStatus,
  ) => Promise<void>;
}

const useDirectSalesPlannerActions = (): UseDirectSalesPlannerActions => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLineLevelChange = useCheckDirectSalesLineLevelChange();
  const isCampaignLevelChange = useCheckDirectSalesCampaignLevelChange();
  const { areAllMandatoryFieldsFilled } = useCheckDirectSalesMandatoryFields();
  const { cancelFunctions } = useCommonPlannerActions();

  const isActionsDisabled = useSelector((state: Store) => state.dealManagement.isActionsDisabled);
  const isFetchingAvailability = useSelector((state: Store) => state.dealManagement.isFetchingAvailability);
  const directSales = useSelector((state: Store) => state.dealManagement.directSales);
  const lines = useSelector((state: Store) => state.dealManagement.backupFormData.lines) as DirectSalesLine[];
  const campaignId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const dealLineId = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.lineId);
  const advertiser = useSelector((state: Store) => state.dealManagement.commonDeal.advertiser);
  const brand = useSelector((state: Store) => state.dealManagement.commonDeal.brand);
  const productCategory = useSelector((state: Store) => state.dealManagement.commonDeal.productCategory);
  const agency = useSelector((state: Store) => state.dealManagement.commonDeal.agency);
  const specialist = useSelector((state: Store) => state.dealManagement.commonDeal.specialist);
  const salesPerson = useSelector((state: Store) => state.dealManagement.commonDeal.salesPerson);
  const salesTeam = useSelector((state: Store) => state.dealManagement.commonDeal.salesTeam);
  const adminPerson = useSelector((state: Store) => state.dealManagement.commonDeal.adminPerson);
  const dealName = useSelector((state: Store) => state.dealManagement.commonDeal.dealName);
  const currentLine = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine);
  const marketId = useSelector((state: RootState) => state.publisher.configuration.marketId);
  const currentLineStatus = useSelector((state: Store) => state.dealManagement.directSales.state);
  const transientState = useSelector((state: Store) => state.dealManagement.directSales.transientState);
  const transientStateDealLines = useSelector((state: Store) => state.dealManagement.directSales.transientLineStates);

  const { campaignId: originalCampaignId } = useParams();

  const checkAllocateHandler = (): void => {};

  const getUpdatedDirectSalesCampaign = async (
    customCampaignId: string = campaignId,
  ): GetUpdatedDirectSalesCampaignResult => {
    const result = await getDirectSalesCampaign(customCampaignId, marketId, cancelFunctions);
    const transformResult = transformDirectSalesBookingResponse(result);
    dispatch(updateDirectSalesSessionLock(transformResult.sessionLockState));

    return {
      ...result,
      ...transformResult,
    };
  };

  const showSave = isLineLevelChange || isCampaignLevelChange;
  const showConfirm = areAllMandatoryFieldsFilled;
  const showSendForApproval = areAllMandatoryFieldsFilled;
  const isSavingDisabled =
    isActionsDisabled ||
    isFetchingAvailability ||
    !areAllMandatoryFieldsFilled ||
    !currentLine.availability.assets.length;

  const isCommitDisabled = useMemo(() => {
    return isTransientCommitDisabled({
      areAllMandatoryFieldsFilled,
      transientStateDealLines,
      lines,
    });
  }, [currentLineStatus?.status, JSON.stringify(lines), transientStateDealLines]);

  const showBannerInformation = async (
    bannerInformation: CampaignVersioningErrors | CampaignVersioningEditingStatus,
  ): Promise<void> => {
    const bannerDisplayInformation = getCampaignVersioningMessageExtended(
      /* eslint-disable @typescript-eslint/no-use-before-define */
      navigate,
      discardChanges,
      commitTransientDeal,
      editDeal,
      isCommitDisabled,
      /* eslint-enable @typescript-eslint/no-use-before-define */
    )[bannerInformation];

    dispatch(showBanner(bannerDisplayInformation));
  };

  useEffect(() => {
    if (transientState) {
      showBannerInformation(transientState);
    }
  }, [isCommitDisabled, transientState]);

  const confirmChanges = useCallback(
    async (dealLineStates: DirectSalesIntendedDealLineState): Promise<void> => {
      dispatch(changeFormParams({ isEditingDisabled: true }));

      try {
        await updateTransientDealState(
          {
            transientAction: DirectSalesTransientStates.ACCEPT,
            intendedDealLineStates: mapDealLineStatesToEventState(dealLineStates),
          },
          marketId,
          campaignId,
          cancelFunctions,
        );

        if (!originalCampaignId) return;

        const { deal: commonDeal, lines: updatedLines } = await getUpdatedDirectSalesCampaign(originalCampaignId);

        const poll = pollDealSalesCampaign(marketId, originalCampaignId, cancelFunctions);

        const pollCompleteHandler = (): void => {
          dispatch(
            setLoadedDirectSalesData({
              commonDeal,
              lines: updatedLines,
            }),
          );
          dispatch(changeFormParams({ isEditingDisabled: false }));
          dispatch(changeDealData({ dealId: originalCampaignId }));
          dispatch(clearTransientLineStates());
          dispatch(hideBanner());
        };

        poll(({ data: { id } }: { data: { id: string } }): boolean => {
          if (!isDirectSalesCampaignCloned(id)) {
            pollCompleteHandler();
            return false;
          }

          return true;
        }, 1000);
      } catch (error) {
        dispatch(notifyError({ message: error.message }));
        dispatch(changeFormParams({ isEditingDisabled: false }));
      }
    },
    [marketId, campaignId],
  );

  const commitTransientDeal = useCallback(async (): Promise<void> => {
    if (!isDirectSalesCampaignCloned(campaignId)) return;

    if (!transientStateDealLines || isEmpty(transientStateDealLines)) return;

    const canConfirmChanges = isValidAttemptToConfirmChanges({
      transientStateDealLines,
      showBannerInformation,
      lines,
    });

    if (canConfirmChanges) {
      confirmChanges(transientStateDealLines);
    }
  }, [campaignId, JSON.stringify(transientStateDealLines), JSON.stringify(lines), confirmChanges]);

  const showErrorBannerInformation = (errorDetail: ErrorDetailType): void => {
    if (!mapCampaignVersioningErrors[errorDetail]) return;

    showBannerInformation(mapCampaignVersioningErrors[errorDetail]);
  };

  const handleFailedStatus = (error: CampaignVersioningErrors): boolean => {
    dispatch(changeFormParams({ isEditingDisabled: false, isLoading: false }));

    showBannerInformation(error);

    return false;
  };

  const handleSuccessStatus = (id: string): boolean => {
    if (isDirectSalesCampaignCloned(id)) showBannerInformation(CampaignVersioningEditingStatus.IN_PROGRESS);
    else dispatch(hideBanner());

    return true;
  };

  const getSolution = useCallback(
    async (pollCompleteHandler: () => Promise<void>): Promise<void> => {
      dispatch(changeFormParams({ isEditingDisabled: true, isLoading: true }));
      try {
        await getDirectSalesSolution(campaignId, marketId, cancelFunctions);

        const poll = pollDealLineState(marketId, campaignId, dealLineId, cancelFunctions);

        await poll(({ data: { states } }: { data: DirectSalesCampaignDealLineStateResponse }): boolean => {
          const lineStatus = states[dealLineId].state.status;

          if (lineStatus === DirectSalesStatusOption.DRAFT_SOLVING) return true;

          if (lineStatus === DirectSalesStatusOption.DRAFT_SOLVED) return handleSuccessStatus(campaignId);

          if (lineStatus === DirectSalesStatusOption.DRAFT_FAILED_PRICE) {
            // TODO: To be removed when Pricing becomes stable
            pollCompleteHandler();

            return handleFailedStatus(CampaignVersioningErrors.FAILURE_PRICE);
          }

          if (lineStatus === DirectSalesStatusOption.DRAFT_FAILED_SOLVE)
            return handleFailedStatus(CampaignVersioningErrors.FAILURE_SOLVE);

          if (lineStatus === DirectSalesStatusOption.DRAFT_PRICED) {
            pollCompleteHandler();

            if (isDirectSalesCampaignCloned(campaignId))
              showBannerInformation(CampaignVersioningEditingStatus.IN_PROGRESS);
            else dispatch(hideBanner());

            dispatch(
              changeFormParams({
                isEditingDisabled: false,
                isLoading: false,
              }),
            );

            return false;
          }

          return true;
        }, 1000);
      } catch (error) {
        dispatch(
          changeFormParams({
            isEditingDisabled: false,
            isLoading: false,
          }),
        );
        dispatch(notifyError({ message: error.message }));
      }
    },
    [campaignId, marketId, dealLineId],
  );

  const saveDealLineState = async (
    state: DirectSalesState,
    pollCompleteHandler: () => Promise<void>,
  ): Promise<void> => {
    if (!campaignId || !dealLineId) {
      return;
    }

    dispatch(changeFormParams({ isEditingDisabled: true, isLoading: true }));

    const isOptionStatus = state.status === DirectSalesStatusOption.OPTION;

    if (isOptionStatus && !state.expires) {
      dispatch(
        notifyError({
          message: 'Please add date for OPTION status',
          timeout: NOTIFICATION_TIMEOUT.DEFAULT,
        }),
      );
      return;
    }

    const expires =
      state.expires && isOptionStatus
        ? { expires: getDateInRequestFormat(state.expires, getTimeInDate(state.expires)) }
        : {};

    const data = JSON.stringify({
      event: DirectSalesStatusOptionEvent[state.status],
      ...expires,
    });

    dispatch(
      notifyInfo({
        message: 'Saving your line changes',
        timeout: NOTIFICATION_TIMEOUT.DEFAULT,
      }),
    );

    try {
      await updateDealLineState(data, marketId, originalCampaignId ?? campaignId, dealLineId, cancelFunctions);

      const poll = pollDealLineState(marketId, originalCampaignId ?? campaignId, dealLineId, cancelFunctions);

      await poll(({ data: { states } }: { data: DirectSalesCampaignDealLineStateResponse }): boolean => {
        const lineStatus = states[dealLineId].state.status;

        if (!STATUS_PROCESS_END_STATUSES.includes(lineStatus)) {
          return true;
        }

        pollCompleteHandler();
        dispatch(
          changeFormParams({
            isEditingDisabled: false,
            isLoading: false,
          }),
        );

        dispatch(
          notifySuccess({
            message: 'Successfully updated deal line state',
            timeout: NOTIFICATION_TIMEOUT.DEFAULT,
          }),
        );

        return false;
      }, 1000);
    } catch (error) {
      dispatch(
        changeFormParams({
          isEditingDisabled: false,
          isLoading: false,
        }),
      );

      dispatch(
        notifyError({
          message: 'Unable to update deal line state',
          timeout: NOTIFICATION_TIMEOUT.DEFAULT,
        }),
      );
    }
  };

  const submitNewDeal = async (): Promise<void> => {
    const payload = {
      deal: {
        name: dealName,
        advertiser: advertiser as Advertiser,
        brand: brand as Brand,
        productCategory: productCategory as CodeNameModel,
        agency,
        specialist,
        salesPerson,
        salesTeam,
        adminPerson,
      },
      lines: transformAllLineData([{ name: generateUniqueDealLineName([], 1), ...directSales }]),
    };

    const formData = transformDirectSalesWithLineFormRequest(payload);

    dispatch(changeFormParams({ isEditingDisabled: true }));
    dispatch(
      notifyInfo({
        message: 'Saving your campaign changes',
        timeout: NOTIFICATION_TIMEOUT.DEFAULT,
      }),
    );

    try {
      const { id } = await createDirectSalesCampaign(formData, marketId, cancelFunctions);

      if (id) {
        navigate(`/planner/direct-sales/${id}`);
      }
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
      throw new Error(error);
    } finally {
      dispatch(changeFormParams({ isEditingDisabled: false }));
    }
  };

  const editDeal = async ({
    currentLineData,
    directSalesData,
  }: {
    currentLineData?: Partial<Line>;
    directSalesData?: Partial<DirectSales>;
    // eslint-disable-next-line sonarjs/cognitive-complexity
  } = {}): Promise<void> => {
    const dealLines = mergeCurrentLineToAllLines(lines as DirectSalesLine[], {
      ...currentLine,
      ...directSales,
      ...currentLineData,
      ...directSalesData,
    });

    const payload = {
      deal: {
        name: dealName,
        advertiser: advertiser as Advertiser,
        brand: brand as Brand,
        productCategory: productCategory as CodeNameModel,
        agency,
        specialist,
        salesPerson,
        salesTeam,
        adminPerson,
      },
      lines: transformAllLineData(dealLines),
    };

    const formData = transformDirectSalesWithLineFormRequest(payload);

    dispatch(changeFormParams({ isEditingDisabled: true }));

    dispatch(
      notifyInfo({
        message: 'Saving your line changes',
        timeout: NOTIFICATION_TIMEOUT.DEFAULT,
      }),
    );

    try {
      await editDirectSalesCampaign(formData, marketId, campaignId, cancelFunctions);
      const {
        campaign: {
          header,
          body: {
            campaignDefinition: { dealLines: savedDealLines },
          },
        },
      } = await getUpdatedDirectSalesCampaign();

      dispatch(
        changeDealData({
          salesPerson: header.salesPerson || null,
          salesTeam: header.salesTeam || null,
          adminPerson: header.adminPerson || null,
        }),
      );

      const savedLine = savedDealLines.find(({ name }) => name === currentLine.name || name === currentLineData?.name);

      if (savedLine && savedLine.id) {
        const {
          state: { status },
          state,
        } = savedLine;

        if (!isDirectSalesLinePriced(status)) dispatch(clearDirectSalesAllocation());

        if (!currentLine.lineId) {
          dispatch(addDirectSalesLineId(savedLine.id));
        } else {
          const isDirectSalesChange = !isEmpty(directSalesData);
          const isCurrentLineChange = !isEmpty(currentLineData);

          if (!isDirectSalesChange && !isCurrentLineChange) return;

          dispatch(changeDirectSalesData({ state }));

          if (isDirectSalesChange) dispatch(changeDirectSalesData(directSalesData));

          if (isCurrentLineChange) dispatch(changeDealCurrentLineData(currentLineData));

          dispatch(
            updateBackupFormDataLine({
              lineId: currentLine.lineId,
              newBackupLineData: {
                ...currentLineData,
                ...directSalesData,
                state,
              },
            }),
          );
          dispatch(hidePlannerSecondaryPanel());
        }
        dispatch(
          notifySuccess({
            message: 'Successfully saved the campaign changes',
            timeout: NOTIFICATION_TIMEOUT.DEFAULT,
          }),
        );
      }
    } catch (error) {
      showErrorBannerInformation(error.response?.data.detail);

      dispatch(notifyError({ message: error.message }));
      throw new Error(error);
    } finally {
      dispatch(changeFormParams({ isEditingDisabled: false }));
    }
  };

  const editDealLine = async ({
    currentLineData,
    directSalesData,
  }: {
    currentLineData?: Partial<Line>;
    directSalesData?: Partial<DirectSales>;
    // eslint-disable-next-line sonarjs/cognitive-complexity
  } = {}): Promise<void> => {
    const currentLineId = currentLine.lineId;

    const dealLine = {
      ...currentLine,
      ...directSales,
      ...currentLineData,
      ...directSalesData,
    };

    const [payload] = transformAllLineData([dealLine]);
    const formData = transformDirectSalesDealLineRequest(payload);

    dispatch(changeFormParams({ isEditingDisabled: true }));

    dispatch(
      notifyInfo({
        message: 'Saving your line changes',
        timeout: NOTIFICATION_TIMEOUT.DEFAULT,
      }),
    );

    try {
      const { id } = await updateDirectSalesDealLine(formData, marketId, campaignId, currentLineId, cancelFunctions);
      if (campaignId !== id) {
        dispatch(changeDealData({ dealId: id }));
        if (isDirectSalesCampaignCloned(id)) {
          showBannerInformation(CampaignVersioningEditingStatus.IN_PROGRESS);
        }
      }

      const {
        campaign: {
          header,
          body: {
            campaignDefinition: { dealLines: savedDealLines },
          },
        },
      } = await getUpdatedDirectSalesCampaign();
      dispatch(
        changeDealData({
          salesPerson: header.salesPerson || null,
          salesTeam: header.salesTeam || null,
          adminPerson: header.adminPerson || null,
        }),
      );

      const savedLine = savedDealLines.find(({ name }) => name === currentLine.name || name === currentLineData?.name);

      if (savedLine && savedLine.id) {
        const {
          state: { status },
          state,
        } = savedLine;

        if (!isDirectSalesLinePriced(status)) dispatch(clearDirectSalesAllocation());

        if (!currentLine.lineId) {
          dispatch(addDirectSalesLineId(savedLine.id));
        } else {
          const isDirectSalesChange = !isEmpty(directSalesData);
          const isCurrentLineChange = !isEmpty(currentLineData);

          if (!isDirectSalesChange && !isCurrentLineChange) return;

          dispatch(changeDirectSalesData({ state }));

          if (isDirectSalesChange) dispatch(changeDirectSalesData(directSalesData));

          if (isCurrentLineChange) dispatch(changeDealCurrentLineData(currentLineData));
          dispatch(
            updateBackupFormDataLine({
              lineId: currentLine.lineId,
              newBackupLineData: {
                ...currentLineData,
                ...directSalesData,
                state,
              },
            }),
          );
          dispatch(clearActiveObjective());
          dispatch(hidePlannerSecondaryPanel());
        }
        dispatch(
          notifySuccess({
            message: 'Successfully saved the line changes',
            timeout: NOTIFICATION_TIMEOUT.DEFAULT,
          }),
        );
      }
    } catch (error) {
      showErrorBannerInformation(error.response?.data.detail);

      dispatch(notifyError({ message: error.message }));
    } finally {
      dispatch(changeFormParams({ isEditingDisabled: false }));
    }
  };

  const saveDeal = async (): Promise<void> => {
    dispatch(hideDisclaimer());

    if (!campaignId) {
      await submitNewDeal();
    } else {
      await editDeal();
    }
  };

  const discardChanges = useCallback(
    async (providedCampaignId: string): Promise<void> => {
      dispatch(changeFormParams({ isEditingDisabled: true }));

      try {
        await updateTransientDealState(
          { transientAction: DirectSalesTransientStates.CANCEL },
          marketId,
          providedCampaignId,
          cancelFunctions,
        );

        if (!originalCampaignId) return;

        const { deal: commonDeal, lines: updatedLines } = await getUpdatedDirectSalesCampaign(originalCampaignId);

        dispatch(
          setLoadedDirectSalesData({
            commonDeal,
            lines: updatedLines,
          }),
        );
        dispatch(changeFormParams({ isEditingDisabled: false }));
        dispatch(changeDealData({ dealId: originalCampaignId }));
        dispatch(clearTransientLineStates());
        dispatch(hideBanner());
      } catch (error) {
        dispatch(notifyError({ message: error.message }));
        dispatch(changeFormParams({ isEditingDisabled: false }));
      }
    },
    [marketId, campaignId],
  );

  const deleteDealLine = useCallback(
    async (lineId: string): Promise<void> => {
      dispatch(changeFormParams({ isEditingDisabled: true }));

      dispatch(
        notifyInfo({
          message: 'Saving your line changes',
          timeout: NOTIFICATION_TIMEOUT.DEFAULT,
        }),
      );

      try {
        const { id } = await deleteDirectSalesDealLine(marketId, campaignId, lineId, cancelFunctions);

        if (id) {
          dispatch(removeDirectSalesLine());
          dispatch(clearDirectSalesAllocation());
          dispatch(clearActiveObjective());
          dispatch(changeDirectSalesData({ state: { status: DirectSalesStatusOption.DRAFT_UPDATING } }));

          dispatch(
            notifySuccess({
              message: 'Successfully saved the line changes',
              timeout: NOTIFICATION_TIMEOUT.DEFAULT,
            }),
          );
        }

        dispatch(changeFormParams({ isEditingDisabled: false }));
      } catch (error) {
        dispatch(notifyError({ message: error.message }));
        dispatch(changeFormParams({ isEditingDisabled: false }));
      }
    },
    [marketId, campaignId],
  );

  return {
    checkAllocateHandler,
    getUpdatedDirectSalesCampaign,
    statusConditions: {
      showConfirm,
      showSendForApproval,
      showSave,
    },
    isSavingDisabled,
    saveDeal,
    editDeal,
    editDealLine,
    deleteDealLine,
    getSolution,
    saveDealLineState,
    discardChanges,
    showBannerInformation,
  };
};

export default useDirectSalesPlannerActions;
