import { useSelector } from 'react-redux';
import { thoughtspot } from 'config';
import { initialiseThoughtspot } from 'utils/thoughtspotInit';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { Store } from 'components/common/types/Store.types';
import LiveboardVisuals from 'components/patterns/LiveboardVisuals';
import { filterByMarket, hideMarketFilterStyle, labsReportVisibleActions } from '../LabsReportDropdown/utils';

const ProofOfPlay: React.FC = () => {
  const { localeCode, marketId } = useSelector((state: Store) => state.publisher.configuration);
  const cancelFunctions = useCancelRequest();

  initialiseThoughtspot(cancelFunctions, localeCode);

  return (
    <div className="pt-4 h-full">
      <LiveboardVisuals
        liveboardId={thoughtspot.proofOfPlayLiveboardId}
        customizations={hideMarketFilterStyle}
        visibleActions={labsReportVisibleActions}
        filterByOptions={[filterByMarket(marketId)]}
      />
    </div>
  );
};

export default ProofOfPlay;
