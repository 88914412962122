import { Metadata } from '../../@types/global/pendo';

export const trackEventToPendo = (eventName: string, metadata?: Metadata): void => {
  if (!window.pendo?.isReady) {
    // eslint-disable-next-line no-console
    console.warn(`Pendo is not initialized. So, event "${eventName}" wasn't tracked.`);
    return;
  }

  window.pendo.track(eventName, metadata);
};
