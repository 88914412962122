import { RouteFrameCode } from 'components/common/types/Deal.types';
import { FileContent, FileList } from 'components/common/types';
import {
  DirectSalesFilter,
  DirectSalesFilterTypes,
  DirectSalesNestedFilter,
  DirectSalesUnitListFilter,
} from 'components/common/types/DirectSalesCampaign.types';
import { File } from 'utils/framesUtils.types';

export const mergeFilesByFilename = (listFiles: FileList[], listFilesExcluded: FileList[]): FileList[] => {
  const allFiles = [...listFiles, ...listFilesExcluded];

  const mergedFiles = allFiles.reduce<FileList[]>((acc, { name, include, file, fileContents }: FileList) => {
    const existingFile = acc.find((existing: FileList) => existing.name === name);

    if (existingFile) {
      existingFile.fileContents = [...(existingFile.fileContents || []), ...(fileContents || [])];
      existingFile.include = existingFile.include ?? include;
    } else {
      acc.push({
        name,
        include,
        file,
        fileContents: fileContents ? [...fileContents] : [],
      });
    }

    return acc;
  }, []);

  return mergedFiles.map(({ fileContents, name, file }) => {
    const includes = (fileContents || []).map(({ include }) => include);
    const allSame = new Set(includes).size === 1;

    return {
      file,
      name,
      include: allSame ? includes[0] : undefined,
      fileContents,
    };
  });
};

export const mapIndividuallySelectedFrameData = ({
  frames,
  include,
}: {
  frames: string[];
  include: boolean;
}): FileContent[] => {
  return (
    frames.map((id) => {
      return {
        include,
        code: id,
        name: id,
      };
    }) ?? []
  );
};

export const getIndividualRouteFrameCodes = (
  includedFrames: DirectSalesUnitListFilter[],
  excludedFrames: DirectSalesUnitListFilter[],
): FileContent[] => {
  const excludedInvdividualSelectedFileFilters = excludedFrames.filter(({ filename }) => filename === null);
  const includedIndividualSelectedFrames = includedFrames.filter(({ filename }) => filename === null);

  const includedRouteFrameCodes = includedIndividualSelectedFrames.length
    ? mapIndividuallySelectedFrameData({
        frames: includedIndividualSelectedFrames?.[0].unitsIds,
        include: true,
      })
    : [];
  const excludedRouteFrameCodes = excludedInvdividualSelectedFileFilters.length
    ? mapIndividuallySelectedFrameData({
        frames: excludedInvdividualSelectedFileFilters?.[0].unitsIds,
        include: false,
      })
    : [];

  return [...includedRouteFrameCodes, ...excludedRouteFrameCodes];
};

export const mapUploadedFileData = ({
  uploadedFiles,
  include,
}: {
  uploadedFiles: DirectSalesUnitListFilter[];
  include: boolean;
}): FileList[] => {
  return uploadedFiles.map(({ filename, unitsIds }) => {
    const file = {
      path: filename,
      name: filename,
    } as Partial<File>;

    return {
      include,
      file,
      name: filename,
      fileContents: mapIndividuallySelectedFrameData({ frames: unitsIds, include }),
    } as FileList;
  });
};

export const getUploadedRouteFrameCodes = (
  includedFrames: DirectSalesUnitListFilter[],
  excludedFrames: DirectSalesUnitListFilter[],
): FileList[] => {
  const excludedUploadedFileFilters = excludedFrames.filter(({ filename }) => filename !== null);
  const includedUploadedFileFilters = includedFrames.filter(({ filename }) => filename !== null);

  const includedListFiles = mapUploadedFileData({ uploadedFiles: includedUploadedFileFilters, include: true });
  const excludedListFiles = mapUploadedFileData({ uploadedFiles: excludedUploadedFileFilters, include: false });

  return mergeFilesByFilename(includedListFiles, excludedListFiles);
};

export const transformUnitListFiltersFromResponse = (
  filter?: DirectSalesFilter,
): {
  listFiles: FileList[];
  routeFrameCodes: RouteFrameCode[];
} => {
  if (!filter) {
    return {
      listFiles: [],
      routeFrameCodes: [],
    };
  }

  const notFilters = filter?.filters.find(
    ({ type }) => type === DirectSalesFilterTypes.NotFilter,
  ) as DirectSalesNestedFilter;
  const excludedFilters = notFilters
    ? (notFilters.filter.filters.filter(({ type }) => type === 'UnitListFilter') as DirectSalesUnitListFilter[])
    : [];
  const includedFilters = filter.filters.filter(({ type }) => type === 'UnitListFilter') as DirectSalesUnitListFilter[];
  const listFiles = getUploadedRouteFrameCodes(includedFilters, excludedFilters);
  const routeFrameCodes = getIndividualRouteFrameCodes(includedFilters, excludedFilters);

  return {
    listFiles: listFiles ?? [],
    routeFrameCodes: routeFrameCodes ?? [],
  };
};
