import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { isEqual } from 'lodash';
import { DirectSalesLine } from 'components/common/types/DirectSalesCampaign.types';

export const useCheckDirectSalesLineLevelChange = (): boolean => {
  const backupFormDataLines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);
  const currentLine = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine);
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const mediaType = useSelector((state: Store) => state.dealManagement.directSales.mediaType);

  const backupLine = useMemo(() => {
    if (!backupFormDataLines?.length) return {};

    const { startDate, endDate, environments, selectedDays, ...data } =
      (backupFormDataLines.find((line) => line.lineId === currentLine.lineId) as DirectSalesLine) ??
      backupFormDataLines[0];

    return {
      startDate,
      endDate,
      environments,
      selectedDays,
      mediaType: data?.mediaType,
    };
  }, [backupFormDataLines, currentLine.lineId]);

  const { startDate, endDate, environments, selectedDays } = currentLine;

  const isDirectSalesLineChange = useMemo(() => {
    if (!backupLine) return false;

    return !isNewDeal && mediaType !== backupLine.mediaType;
  }, [backupLine, mediaType, isNewDeal, backupFormDataLines]);

  const isCommonDealLineLevelChange = useMemo(
    () =>
      !isNewDeal &&
      !isEqual(
        {
          startDate: backupLine.startDate,
          endDate: backupLine.endDate,
          environments: backupLine.environments,
          selectedDays: backupLine.selectedDays,
        },
        {
          startDate,
          endDate,
          environments,
          selectedDays,
        },
      ),
    [backupLine, currentLine, isNewDeal],
  );

  return isCommonDealLineLevelChange || isDirectSalesLineChange;
};
