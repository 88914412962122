import { DAYPART_HOURS } from './constants';
import { GetDaypartHoursIndexes } from './DaypartTargeting.types';

export const getDaypartHoursIndexes: GetDaypartHoursIndexes = (startOffset, endOffset) => {
  const hourStartIndex = DAYPART_HOURS.findIndex((hour) => hour.startOffset === startOffset);
  const hourEndIndex = DAYPART_HOURS.findIndex((hour) => hour.endOffset === endOffset);

  const indexes = [];

  for (let i = hourStartIndex; i <= hourEndIndex; i++) {
    indexes.push(i);
  }

  return indexes;
};
