export interface IconButtonProps {
  buttonSize?: ButtonSize;
  dataTestId?: string;
  handleOnClick?: () => void;
  icon: string;
  isDisabled?: boolean;
  name: string;
  theme?: Theme;
  dataPendoId?: string;
}

export enum ButtonSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  EXTRA_SMALL = 'EXTRA_SMALL',
}

export enum Theme {
  DANGER = 'DANGER',
  PLAIN = 'PLAIN',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
}

export const IconButtonSize: Record<
  ButtonSize,
  {
    buttonStyle: string;
    iconStyle: string;
  }
> = {
  LARGE: {
    buttonStyle: 'p-[7px] rounded-md',
    iconStyle: 'w-5 h-5',
  },
  MEDIUM: {
    buttonStyle: 'p-[5px] rounded-md',
    iconStyle: 'w-4 h-4',
  },
  SMALL: {
    buttonStyle: 'p-[3px] rounded',
    iconStyle: 'w-4 h-4',
  },
  EXTRA_SMALL: {
    buttonStyle: 'p-[2px] rounded',
    iconStyle: 'w-3 h-3',
  },
};

export const IconButtonTheme: Record<
  Theme,
  {
    buttonStyle: string;
    iconColor: string;
  }
> = {
  PLAIN: {
    buttonStyle: 'bg-transparent hover:bg-neutral-950-opacity-5 active:bg-neutral-950-opacity-10 border-transparent',
    iconColor: 'text-neutral-900',
  },
  DANGER: {
    buttonStyle: 'bg-pinkRed-800 hover:bg-pinkRed-700 active:bg-pinkRed-900 border-transparent',
    iconColor: 'text-pinkRed-50',
  },
  PRIMARY: {
    buttonStyle: 'bg-primary-900 hover:bg-primary-700 active:bg-primary-600 border-transparent',
    iconColor: 'text-primary-50',
  },
  SECONDARY: {
    buttonStyle: 'bg-neutral-50 hover:bg-neutral-100 active:bg-neutral-200 border-neutral-950-opacity-10',
    iconColor: 'text-primary-600',
  },
  TERTIARY: {
    buttonStyle:
      'bg-primary-600-opacity-5 hover:bg-primary-600-opacity-10 active:bg-primary-600-opacity-20 border-transparent',
    iconColor: 'text-primary-600',
  },
};
