import {
  DirectSalesAllocationSolutionResponse,
  DirectSalesLineAllocation,
  DirectSalesStatusOption,
} from 'components/common/types/DirectSalesCampaign.types';
import { TargetObjectiveId } from 'consts/targetCriteria';
import { getObjectiveTargetIds } from 'store/dealManagement/utils';
import { isDirectSalesLinePriced } from './getIsDirectSalesLinePriced';

const transformDirectSalesLineAllocation = (
  allocationResponse: DirectSalesAllocationSolutionResponse | null,
  lineStatus: DirectSalesStatusOption,
  lineId?: string,
): DirectSalesLineAllocation => {
  if (!allocationResponse || !lineId || !isDirectSalesLinePriced(lineStatus)) return { networks: [], measures: {} };

  const campaignAllocation = allocationResponse?.measures?.campaignMeasures?.[0];
  const lineAllocationResponse = campaignAllocation?.bookingMeasures?.find((measure) => measure.bookingId === lineId);

  if (!lineAllocationResponse) return { networks: [], measures: {} };

  const lineAllocation: DirectSalesLineAllocation = { networks: [], measures: {} };

  const networksAllocationResponse = lineAllocationResponse.objectiveMeasures.filter(
    (objective) => getObjectiveTargetIds(Number(objective.objectiveId)).targetIndex === TargetObjectiveId.NETWORK,
  );

  if (networksAllocationResponse.length) {
    lineAllocation.networks = networksAllocationResponse.map((networkAllocationResponse) => ({
      objectiveId: Number(networkAllocationResponse.objectiveId),
      status: networkAllocationResponse.status,
      value: networkAllocationResponse.value,
    }));
  }

  lineAllocationResponse.objectiveMeasures.forEach(({ value: allocated, status, objectiveId }) => {
    lineAllocation.measures[+objectiveId] = { allocated, status };
  });

  return lineAllocation;
};

export default transformDirectSalesLineAllocation;
