import { useLoadLookup } from 'components/common/FilterArea/Filters/useLoadLookup';
import { CodeNameModel } from 'components/common/types';
import { InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import { Store } from 'components/common/types/Store.types';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { ShadowStyle } from 'consts/shadow';
import { Padding } from 'lib/BaseSelect/BaseSelect.types';
import { Label } from 'lib/Label';
import { useDispatch, useSelector } from 'react-redux';
import { changeDealInfo } from 'store/dealManagement/actions';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';
import { isReadOnly } from 'utils/isReadOnly';

const SelectSalesTeam: React.FC = () => {
  const dispatch = useDispatch();

  const { isDirectSalesCampaignType } = useCampaignType();

  const salesTeam = useSelector((state: Store) => state.dealManagement.commonDeal.salesTeam);
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const isAdServerMarket = useSelector(getIsAdServer);
  const isReseller = useSelector(getIsReseller);

  const { onLoadSalesTeam } = useLoadLookup();

  const handleSelectSalesTeam = (key: string, value: CodeNameModel): Promise<void> =>
    dispatch(changeDealInfo({ [key]: value }));

  if (!isDirectSalesCampaignType && (!isAdServerMarket || isReseller)) return null;

  return (
    <>
      <Label id="salesTeam" theme={InputTheme.FLAT_GRAY} label="Sales team" />
      <AsyncSelect
        name="salesTeam"
        dataTestId="async-select-salesTeam"
        placeholder=""
        theme={InputTheme.FLAT_GRAY}
        size={InputSize.EXTRA_SMALL}
        padding={Padding.NONE}
        shadow={ShadowStyle.NONE}
        shape={InputShape.ROUNDED}
        selectedValue={salesTeam}
        loadData={onLoadSalesTeam}
        onSelect={handleSelectSalesTeam}
        defaultOptions={salesTeam}
        isDisabled={isReadOnly(bookingStatusCode, isEditingDisabled)}
      />
    </>
  );
};

export default SelectSalesTeam;
