import { useSelector } from 'react-redux';
import cx from 'classnames';
import Button, { Align, Color, Size } from 'lib/Button';
import { Store } from 'components/common/types/Store.types';
import { FrontEndType, Line } from 'components/common/types/Deal.types';
import Tooltip, { TooltipDirection, TooltipSize, TooltipTheme } from 'components/patterns/Tooltip';
import LineSelect from 'components/pages/Planner/PlannerHeader/LineSelect';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import { getActionConditions } from 'components/pages/DealWithLines/common/getActionConditions';
import { DealLinesProps } from './DealLines.types';

export const DealLines: React.FC<DealLinesProps> = ({
  displayedLines,
  displayDealLinesDropdown,
  onLineSelect,
  onNewLineClick,
  isAddNewLineDisabled,
  isSelectLineDisabled,
  addNewLineLabel = 'Add new deal line',
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const lines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);
  const dealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const currentLine = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine);
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const frontEndType = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.frontEndType);
  const dealManagementState = useSelector((state: Store) => state.dealManagement);
  const dealConfig = useSelector((state: Store) => state.dealConfig);
  const isReseller = useSelector(getIsReseller);
  const isAdServerMarket = useSelector(getIsAdServer);
  const { isDirectSalesCampaignType } = useCampaignType();

  const { createLine } = getActionConditions(dealManagementState, isAdServerMarket, dealConfig, isReseller);

  const noDirectSalesCampaign = isDirectSalesCampaignType && !dealId;

  const isLineActive = (index: number, line: Line): boolean => {
    const isSingleLine = index === 0 && displayedLines.length === 1;

    if (isDirectSalesCampaignType) return isSingleLine || currentLine.lineId === line.lineId;
    return isSingleLine || isAdServerMarket ? currentLine.lineId === line.lineId : currentLine.id === line.id;
  };
  // TODO: Remove direct sales check once the logic for adding lines is ready (ADS-6894)
  const isAddNewLineButtonDisabled =
    frontEndType === FrontEndType.PLANNER
      ? isAddNewLineDisabled || isEditingDisabled || !isDirectSalesCampaignType || noDirectSalesCampaign
      : createLine.disabled;

  return (
    <>
      {displayedLines?.map((line, index) => {
        const isDisplayedLineActive = isLineActive(index, line);
        const isLineButtonDisabled =
          noDirectSalesCampaign || ((isSelectLineDisabled || isEditingDisabled) && !isDisplayedLineActive);

        return (
          <Tooltip
            tooltip={line.name}
            direction={TooltipDirection.BOTTOM}
            contentSize={TooltipSize.MAX}
            theme={TooltipTheme.DARK}
            delay
            key={`${line.name}-${line.id}`}
          >
            <Button
              onClick={() => (isDisplayedLineActive ? undefined : onLineSelect?.(line))}
              color={isDisplayedLineActive ? Color.TERTIARY : Color.BLACK_AND_WHITE}
              dataTestId={`planner-header-button-${line.name.replace(/ /g, '')}`}
              size={Size.SMALL}
              isDisabled={isLineButtonDisabled}
              label={!line.name ? 'Deal line 1' : line.name}
              align={Align.START}
              classNames={cx('border flex max-w-[15ch] overflow-hidden leading-tight', {
                'border-primary-600': isDisplayedLineActive,
              })}
            />
          </Tooltip>
        );
      })}
      {displayDealLinesDropdown ? (
        <LineSelect
          lines={lines}
          onSelectOne={onLineSelect}
          onNewLineClick={onNewLineClick}
          selectedLineId={currentLine.lineId}
          isDisabled={isEditingDisabled}
          isAddNewLineDisabled={isAddNewLineDisabled}
          isSelectLineDisabled={isSelectLineDisabled}
        />
      ) : (
        <Tooltip
          tooltip={addNewLineLabel}
          direction={TooltipDirection.BOTTOM}
          delay
          contentSize={TooltipSize.MAX}
          theme={TooltipTheme.DARK}
        >
          <Button
            onClick={onNewLineClick}
            color={Color.TRANSPARENT}
            size={Size.SMALL}
            isDisabled={isAddNewLineButtonDisabled}
            label={`+ ${addNewLineLabel}`}
          />
        </Tooltip>
      )}
    </>
  );
};
