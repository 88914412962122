import { Asset } from 'components/common/types/Asset.types';
import { IJsonSheet } from 'json-as-xlsx';
import { formatNumber, formatPercent, FormatTypeEnum, formatValue } from 'modules/I18N';

import { CodeNameModel } from 'components/common/types';
import { RouteFrameCode } from 'components/common/types/Deal.types';

export type GenerateFrameListDataProps = {
  assets: Asset[];
  isReseller: boolean;
  currencyCode: string;
  localeCode: string;
};

export const generateFrameListData = ({
  assets,
  isReseller,
  currencyCode,
  localeCode,
}: GenerateFrameListDataProps): IJsonSheet[] => {
  return [
    {
      sheet: 'Frame-List',
      columns: [
        ...(isReseller
          ? [
              {
                label: 'Media owner',
                value: 'mediaOwner',
              },
            ]
          : []),
        {
          label: 'Frame ID',
          value: 'frameId',
        },
        {
          label: 'Latitude',
          value: 'latitude',
        },
        {
          label: 'Longitude',
          value: 'longitude',
        },
        {
          label: 'Width',
          value: 'width',
        },
        {
          label: 'Height',
          value: 'height',
        },
        {
          label: 'Timezone',
          value: 'timezone',
        },
        {
          label: 'Visual unit code',
          value: 'visualUnitCode',
        },
        {
          label: 'Availability',
          value: 'availability',
        },
        {
          label: 'Street',
          value: 'street',
        },
        {
          label: 'PostCode',
          value: 'postCode',
        },
        {
          label: 'Route frame code',
          value: 'routeFrameCode',
        },
        {
          label: 'SOT',
          value: 'sot',
        },
        {
          label: 'Impressions',
          value: 'impressions',
        },
        {
          label: 'Price',
          value: 'price',
        },
        {
          label: 'CPM',
          value: 'cpm',
        },
      ],
      content:
        assets?.map(({ siteType, sot: assetSot, impressions: assetImpressions, price, cpm, ...exportData }) => ({
          ...exportData,
          sot: formatPercent({ value: assetSot / 100, localeCode }).replace(/\xA0/g, ' '),
          impressions: formatValue(assetImpressions, FormatTypeEnum.ROUNDED_NUMBER, currencyCode, localeCode),
          price: formatNumber({ value: price as number, localeCode }),
          cpm: formatNumber({ value: cpm as number, localeCode }),
        })) ?? [],
    },
  ];
};

export const frameListSettings = {
  fileName: 'frames-details',
  extraLength: 3,
  writeOptions: {},
};

const updateIncludeExclude = (frameCodes: CodeNameModel[], value: CodeNameModel): CodeNameModel[] => {
  return frameCodes.map((frameCode) =>
    frameCode.code === value.code ? { ...frameCode, include: value.include } : frameCode,
  );
};

const removeFrameByCode = (frames: CodeNameModel[], code: string): CodeNameModel[] => {
  return frames.filter((frame) => frame.code !== code);
};

export const updateFrameSelection = (
  frameCodes: RouteFrameCode[],
  value: CodeNameModel,
  isFrameRemovableOnClick: boolean = true,
): CodeNameModel[] => {
  const frameCodeExists = frameCodes.find(({ code }) => code === value.code);

  if (frameCodeExists) {
    if (value.include !== undefined) {
      return updateIncludeExclude(frameCodes, value);
    }

    if (isFrameRemovableOnClick) {
      return removeFrameByCode(frameCodes, value.code);
    }
    return frameCodes;
  }

  return [...frameCodes, value];
};
