import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import StatusRenderer from 'components/common/StatusRenderer';
import ImageRenderer, { TARGET } from 'components/common/ImageRenderer';
import { AssignCreativeModalFilters, PanelModel } from 'components/common/prop-types/creativeSets';
import withCancelRequest from 'components/hocs/withCancelRequest';
import Table, { TableHeight, TableRowHeight } from 'components/patterns/Table';
import { fetchCreatives } from 'modules/api/content';
import Session from 'modules/Session';
import { CREATIVE_STATUS_BADGE } from 'consts/creative';
import { CreativeStatus } from 'components/common/types/Creative.types';
import emptyListCreativesSvg from 'assets/icons/empty-list-creatives.svg';
import eyeSvg from 'assets/icons/eye.svg';

const mapFiltersToRequestData = ({ dsp, advertiser, brand, creativeType }) => ({
  ...(dsp ? { dspName: dsp.name } : {}),
  ...(advertiser ? { advertiserCode: advertiser.code } : {}),
  ...(brand ? { brandCode: brand.code } : {}),
  ...(creativeType ? { type: creativeType } : {}),
});

const mapSelectedRowToCreative = ({ thumbnail: thumbnailUrl, downloadURL: previewUrl, ...otherCreativeData }) => ({
  thumbnailUrl,
  previewUrl,
  ...otherCreativeData,
});

const columnDefs = [
  {
    headerName: 'Status',
    field: 'status',
    minWidth: 100,
    maxWidth: 100,
    headerTooltip: 'Status',
    cellRenderer: StatusRenderer,
    cellRendererParams: { statuses: CREATIVE_STATUS_BADGE },
    colId: 'status',
  },
  {
    headerName: 'Creative',
    field: 'thumbnail',
    minWidth: 150,
    headerTooltip: 'Creative',
    cellRenderer: ImageRenderer,
    cellRendererParams: (params) => ({
      target: TARGET.BLANK,
      hoverIcon: <SVG src={eyeSvg} />,
      link: params.data?.downloadURL || '',
      isExternalLink: true,
    }),
  },
  { headerName: 'Creative ID', field: 'externalId', minWidth: 160, headerTooltip: 'ID' },
  {
    headerName: 'Size (W x H)',
    field: 'size',
    minWidth: 180,
    headerTooltip: 'SIZE (Width X Height)',
  },
  {
    headerName: 'DSP',
    field: 'dsp',
    minWidth: 190,
    headerTooltip: 'DSP',
    cellRenderer: (params) => params.data?.dsp?.name || '',
  },
  {
    headerName: 'Advertiser',
    field: 'advertiser',
    minWidth: 150,
    headerTooltip: 'Advertiser',
    cellRenderer: (params) => params.data?.advertiser?.name || '',
  },
  {
    headerName: 'Brand',
    field: 'brand',
    minWidth: 130,
    headerTooltip: 'Brand',
    cellRenderer: (params) => params.data?.brand?.name || '',
  },
  { headerName: 'Creative type', field: 'type', minWidth: 130, headerTooltip: 'Creative type' },
];

const CreativesTable = ({ cancelFunctions, filters, onSelectCreative, panel }) => {
  const pageSize = 20;
  const tableApiRef = useRef();

  const prepareCreativesList = (creatives) =>
    creatives.map((creative) => {
      const currentMarket = creative.market.find((market) => market.environment === Session.getEnvironmentId());

      return {
        ...creative,
        status: currentMarket?.moderation[0]?.status ?? CreativeStatus.PENDING,
        advertiser: currentMarket?.commercial?.advertiser,
        brand: currentMarket?.commercial?.brand,
        size: creative.width && creative.height ? `${creative.width} x ${creative.height}` : '',
      };
    });

  const getDataSource = () => ({
    getRows: async (params) => {
      const { content, totalElements } = await fetchCreatives(cancelFunctions, {
        ...mapFiltersToRequestData(filters),
        page: parseInt(params.startRow / pageSize),
        size: pageSize,
        environment: Session.getEnvironmentId(),
        deleted: false,
        width: panel?.width,
        height: panel?.height,
      });

      if (totalElements === 0) {
        tableApiRef.current.showNoRowsOverlay();
        params.successCallback(content, totalElements);
        return;
      }

      tableApiRef.current.hideOverlay();
      params.successCallback(prepareCreativesList(content), totalElements);

      const row = tableApiRef.current.getRowNode(panel?.creative?.id);
      if (row) {
        row.setSelected(true, true);
        onSelectCreative(mapSelectedRowToCreative(row.data));
      } else {
        onSelectCreative(null);
      }
    },
  });

  useEffect(() => {
    tableApiRef.current?.setDatasource(getDataSource());
  }, [filters, panel?.id]);

  const getTableApi = (tableApi) => {
    tableApiRef.current = tableApi;
    tableApi.setDatasource(getDataSource());
  };

  const onSelectionChanged = (event) => {
    const [selectedRow] = event.api.getSelectedRows();

    if (selectedRow) {
      onSelectCreative(mapSelectedRowToCreative(selectedRow));
    }
  };

  return (
    <>
      {!filters.dsp ? (
        <div className="flex flex-col justify-center items-center h-full bg-neutral-100 border rounded-lg border-neutral-100-opacity-10 text-neutral-950-opacity-60 py-20">
          <SVG src={emptyListCreativesSvg} />
          <p className="header-lg mt-10 mb-4">Select a DSP</p>
          <p className="body-base	text-center">
            We cannot find the assets you are looking for. Please, try a different criteria to filter.
          </p>
        </div>
      ) : (
        <div className="h-125">
          <Table
            dataTestId="creatives-detail-table"
            key={pageSize}
            columnDefs={columnDefs}
            getTableApi={getTableApi}
            extraGridOptions={{
              rowModelType: 'infinite',
              rowHeight: TableRowHeight.NORMAL,
              pagination: true,
              paginationPageSize: pageSize,
              cacheBlockSize: pageSize,
              enableCellTextSelection: true,
              rowSelection: 'single',
              onSelectionChanged,
              getRowId: (data) => data.id,
            }}
            tableSize={{ tableHeight: TableHeight.FIT_CONTAINER }}
          />
        </div>
      )}
    </>
  );
};

CreativesTable.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  onSelectCreative: PropTypes.func.isRequired,
  filters: AssignCreativeModalFilters.isRequired,
  panel: PanelModel,
};

CreativesTable.defaultProps = {
  panel: null,
};

export default withCancelRequest(CreativesTable);
