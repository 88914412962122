import React from 'react';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import DropdownMenu from 'lib/DropdownMenu';
import { Color } from 'lib/Button';
import {
  PLANNER_URLS,
  useCampaignTypeSwitch,
} from 'components/pages/Planner/PlannerDealOverlay/CampaignTypeSwitch/useCampaignTypeSwitch';
import { DropdownMenuPosition } from 'lib/DropdownMenu/DropdownMenu.types';
import programmaticIcon from 'assets/icons/programmatic.svg';
import directSalesIcon from 'assets/icons/direct-sales.svg';
import { IconSize } from 'components/common/types/Input.types';
import { CampaignType } from 'components/pages/Planner/PlannerSections/types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Store } from 'components/common/types/Store.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';

export const SelectTradingType: React.FC = () => {
  const { isDirectSalesCampaignType } = useCampaignType();
  const { onChange: onClickCampaignTypeOption } = useCampaignTypeSwitch();
  const { pathname } = useLocation();
  const hasDirectSalesPlannerEnabled = useHasFeatureAccess(FeatureFlags.DIRECT_SALES_PLANNER);

  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);

  const isOptionSelected = (campaignType: CampaignType): boolean => pathname === PLANNER_URLS[campaignType];

  return (
    <div className="w-[340px] flex gap-2 pl-[8px] items-center">
      <DropdownMenu
        label={isDirectSalesCampaignType ? 'Direct sales' : 'Programmatic'}
        color={Color.TRANSPARENT}
        icon={isDirectSalesCampaignType ? directSalesIcon : programmaticIcon}
        iconSize={IconSize.LARGE}
        dropdownMenuPosition={DropdownMenuPosition.LEFT}
        dropdownMenuOptions={[
          {
            id: 'programmatic',
            icon: programmaticIcon,
            label: 'Programmatic',
            onClick: () => onClickCampaignTypeOption(CampaignType.PROGRAMMATIC),
            isSelected: isOptionSelected(CampaignType.PROGRAMMATIC),
          },
          ...(hasDirectSalesPlannerEnabled
            ? [
                {
                  id: 'direct-sales',
                  icon: directSalesIcon,
                  label: 'Direct sales',
                  onClick: () => onClickCampaignTypeOption(CampaignType.DIRECT),
                  isSelected: isOptionSelected(CampaignType.DIRECT),
                },
              ]
            : []),
        ]}
        className="!p-1 text-primary focus:bg-primary-200 focus:ring-2 focus:ring-primary-600"
        isDisabled={!isNewDeal}
      />
    </div>
  );
};
